import React, { useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import { useUpdateVariant } from '@SERVICES';

import { AdminSingleSectionDataVariant, AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import VariantForm from '@PAGES/admin/edit-section/components/VariantForm';
import { VariantFormValues } from '@PAGES/admin/edit-section/components/VariantForm/VariantForm.component';

import styles from './Options.module.scss';

const cx: CX = classnames.bind(styles);

function Options(props: Props) {
    const {
        documentOwnerId,
        documentId,
        chapterId,
        sectionId,
        variant,
        sectionMeta,
        handleToggleDefault,
    } = props;

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId,
        permissions: sectionMeta.permissions,
    });

    const [
        updateVariant,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useUpdateVariant();

    useEffect(() => {
        if (isSuccess) {
            notify.info('Variant options has been updated', {
                toastId: 'update-variant-options',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'update-variant-options-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'update-variant-options-error',
                });
            }
        }
    }, [isError, error]);

    function onMenuOpen() {
        const variantElement: HTMLElement | null = document.querySelector(`[data-variant="${variant.id}"]`);

        if (variantElement) {
            variantElement.style.zIndex = '2';
        }
    }

    function onMenuClose() {
        const variantElement: HTMLElement | null = document.querySelector(`[data-variant="${variant.id}"]`);

        if (variantElement) {
            variantElement.style.zIndex = 'unset';
        }
    }

    return (
        <div className={cx('options')}>
            <VariantForm
                locked={!canEditDocument}
                buttonTitle="Save variant options"
                isLoading={isLoading}
                defaultValues={{
                    isDefault: variant.isDefault,
                    brands: variant.brands,
                    regions: variant.regions,
                    areas: variant.areas,
                    countries: variant.countries,
                    propertyTypes: variant.propertyTypes,
                }}
                sectionMeta={sectionMeta}
                handleToggleDefault={() => handleToggleDefault(variant.id)}
                onSave={(formValues: VariantFormValues) => {
                    const { isDefault } = formValues;

                    updateVariant({
                        documentId,
                        chapterId,
                        sectionId,
                        id: variant.id,
                        name: variant.name || '',
                        isVisible: variant.isVisible,
                        body: variant.body || '',
                        isDefault,
                        brands: isDefault ? [] : formValues.brands,
                        regions: isDefault ? [] : formValues.regions,
                        areas: isDefault ? [] : formValues.areas,
                        countries: isDefault ? [] : formValues.countries,
                        propertyTypes: isDefault ? [] : formValues.propertyTypes,
                    });
                    handleToggleDefault(variant.id, 'update');
                }}
                onMenuOpen={() => {
                    onMenuOpen();
                }}
                onMenuClose={() => {
                    onMenuClose();
                }}
            />
        </div>
    );
}

Options.propTypes = {
    documentOwnerId: PropTypes.number.isRequired,
    documentId: PropTypes.number.isRequired,
    chapterId: PropTypes.number.isRequired,
    sectionId: PropTypes.number.isRequired,
    handleToggleDefault: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Options.propTypes> & {
    variant: AdminSingleSectionDataVariant,
    sectionMeta: AdminSingleSectionMeta,
};

export default Options;
