import React from 'react';
import {
    ComposableMap,
    Geographies,
    Geography,
} from 'react-simple-maps';
import mapData from '@MAPS/data.json';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames/bind';
import styles from './reactMap.module.scss';

const cx: CX = classNames.bind(styles);

const disabledCountries = [
    'Afghanistan',
    'Africa',
    'Argentina',
    'French Southern Territories',
    'Australia',
    'Burundi',
    'Benin',
    'Burkina Faso',
    'Bangladesh',
    'Bahamas',
    'Belize',
    'Bolivia',
    'Brazil',
    'Brunei',
    'Bhutan',
    'Central African Republic',
    'Canada',
    'Chile',
    'China',
    "Cote d'Ivoire",
    'Democratic Republic of Congo',
    'Congo',
    'Colombia',
    'Costa Rica',
    'Cuba',
    'Czechia',
    'Greenland',
    'Dominican Republic',
    'Ecuador',
    'Eritrea',
    'Fiji',
    'French Guiana',
    'Gabon',
    'Gambia',
    'Guinea-Bissau',
    'Equatorial Guinea',
    'Guatemala',
    'Guyana',
    'Honduras',
    'Haiti',
    'Indonesia',
    'India',
    'Iran',
    'Iraq',
    'Jamaica',
    'Japan',
    'Cambodia',
    'South Korea',
    'Laos',
    'Libya',
    'Sri Lanka',
    'Lesotho',
    'Madagascar',
    'Mexico',
    'North Macedonia',
    'Mali',
    'Myanmar',
    'Mongolia',
    'Mozambique',
    'Malaysia',
    'New Caledonia',
    'Niger',
    'Nicaragua',
    'Nepal',
    'New Zealand',
    'Panama',
    'Peru',
    'Philippines',
    'Papua New Guinea',
    'Puerto Rico',
    'North Korea',
    'Paraguay',
    'Russia',
    'Western Sahara',
    'Sudan',
    'South Sudan',
    'Solomon Islands',
    'Sierra Leone',
    'El Salvador',
    'Somalia',
    'Suriname',
    'Eswatini',
    'Syria',
    'Chad',
    'Togo',
    'Thailand',
    'Tajikistan',
    'Turkmenistan',
    'Timor',
    'Trinidad and Tobago',
    'Turkey',
    'Taiwan',
    'Ukraine',
    'Uruguay',
    'United States',
    'Venezuela',
    'Vietnam',
    'Vanuatu',
    'West Bank',
    'Yemen',
    'Zimbabwe',
    'Comoros',
    'Maldives',
    'Marshall Islands',
    'Micronesia (country)',
    'Nauru',
    'Palau',
    'Samoa',
    'Singapore',
    'Tonga',
    'Tuvalu',
    'Antigua and Barbuda',
    'Barbados',
    'Dominica',
    'Grenada',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Andorra',
    'Liechtenstein',
    'San Marino',
    'Kiribati',
    'Sao Tome and Principe',
];

const colors = {
    disabled: '#D6D6DA',
    default: '#00a4ba',
    selected: '#4BB543',
    hover: '#F53',
    pressed: '#E42',
};

interface ReactMapProps {
    handleClick: (geo: any) => void;
    selectedCountry: string | null;
}

function ReactMap(props: ReactMapProps) {
    const { handleClick, selectedCountry } = props;
    // const [position, setPosition] = useState({ coordinates: [15, 16], zoom: 1.18 });

    const getFillColor = (isDisabled: boolean, type: string) => {
        switch (type) {
            case 'default':
                return isDisabled ? colors.disabled : colors.default;
            case 'selected':
                return colors.selected;
            case 'hover':
                return isDisabled ? colors.disabled : colors.hover;
            case 'pressed':
                return isDisabled ? colors.pressed : colors.default;
            default:
                return colors.disabled;
        }
    };

    // const handleZoomIn = () => {
    //     if (position.zoom >= 4) return;
    //     setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
    // };

    // const handleZoomOut = () => {
    //     if (position.zoom <= 1.2) return;
    //     setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
    // };

    // const handleMoveEnd = (endPosition: any) => {
    //     setPosition(endPosition);
    // };

    return (
        <div className={cx('reactMap-wrapper')}>
            <Tooltip
                id="map-tooltip"
                className={cx('tooltip')}
                classNameArrow={cx('arrow')}
            />
            {/* <div className={cx('controls')}>
                <button onClick={handleZoomIn} type="button">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="3"
                    >
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                </button>
                <button onClick={handleZoomOut} type="button">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="3"
                    >
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                </button>
            </div> */}
            <ComposableMap width={1000} height={450}>
                {/* <ZoomableGroup
                    zoom={position.zoom}
                    center={position.coordinates}
                    onMoveEnd={handleMoveEnd}
                > */}
                <Geographies geography={mapData}>
                    {({ geographies }: any) => geographies.map((geo: any) => {
                        const isDisabled = disabledCountries.includes(geo.properties.name);
                        const isSelected = !isDisabled && selectedCountry
                            ? geo.properties.name.toLowerCase().includes(selectedCountry.toLowerCase())
                            : false;

                        return (
                            <React.Fragment key={geo.rsmKey}>
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    data-tooltip-id={`${isDisabled ? '' : 'map-tooltip'}`}
                                    data-tooltip-content={geo.properties.name}
                                    onClick={() => (isDisabled ? null : handleClick(geo))}
                                    style={{
                                        default: {
                                            fill: getFillColor(isDisabled, isSelected ? 'selected' : 'default'),
                                            outline: 'none',
                                        },
                                        hover: {
                                            fill: getFillColor(isDisabled, 'hover'),
                                            outline: 'none',
                                            cursor: isDisabled ? 'default' : 'pointer',
                                        },
                                        pressed: {
                                            fill: getFillColor(isDisabled, 'pressed'),
                                            outline: 'none',
                                        },
                                    }}
                                />
                            </React.Fragment>
                        );
                    })}
                </Geographies>
                {/* </ZoomableGroup> */}
            </ComposableMap>

        </div>
    );
}

export default ReactMap;
