import React, { useEffect } from 'react';
import { InferProps } from 'prop-types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import { AdminDocumentListItem, useCopyDocument } from '@SERVICES';

import useAuth from '@HOOKS/useAuth';
import useDeleteDocumentModal from '@HOOKS/store/modals/useDeleteDocumentModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import TripleCardButtons from '@COMPONENTS/COMMON/buttons/TripleCardButtons';
import RouteButton from '@COMPONENTS/COMMON/buttons/RouteButton';

import styles from './Document.module.scss';

const cx: CX = classnames.bind(styles);

function Document(props: Props) {
    const { doc } = props;

    const { state: { user, permissions } } = useAuth();

    const {
        canDeleteDocument,
    } = useDocumentPermissions({
        documentOwnerId: doc.data.owner.id,
        permissions: doc.meta.permissions,
    });

    const [
        copyDocument,
        {
            isLoading: isCopyLoading,
            isSuccess: isCopySuccess,
            isError: isCopyError,
            error: copyError,
        },
    ] = useCopyDocument();

    const {
        actions: deleteDocumentModalActions,
    } = useDeleteDocumentModal(() => true);

    const navigate = useNavigate();

    const { data: { chapters } } = doc;

    useEffect(() => {
        if (isCopySuccess) {
            notify.info('Document has been copied', {
                toastId: 'copy-document',
            });
        }
    }, [isCopySuccess]);

    useEffect(() => {
        if (isCopyError) {
            if ((copyError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'copy-document-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'copy-document-error',
                });
            }
        }
    }, [isCopyError, copyError]);

    const canCopyDocument = user!.id === doc.data.owner.id || permissions.isSuperuser;

    return (
        <div className={cx('document')}>
            <div className={cx('document-info-wrapper')}>
                <div className={cx('title')}>
                    {doc.data.name}
                </div>
                <div className={cx('header')}>
                    <TripleCardButtons
                        locked={{
                            edit: false,
                            copy: !canCopyDocument,
                            delete: !canDeleteDocument,
                        }}
                        processing={{
                            edit: false,
                            copy: isCopyLoading,
                            delete: false,
                        }}
                        tooltips={{
                            edit: 'Edit document',
                            copy: 'Copy document',
                            delete: 'Delete document',
                        }}
                        onEdit={() => {
                            navigate(
                                ROUTES.admin.documents.id.get(doc.data.id),
                            );
                        }}
                        onCopy={() => {
                            copyDocument({
                                id: doc.data.id,
                            });
                        }}
                        onDelete={() => {
                            deleteDocumentModalActions.open({
                                id: doc.data.id,
                                name: doc.data.name,
                            });
                        }}
                    />
                </div>
                <div className={cx('document-owner')}>
                    <div className={cx('owner')}>
                        Owner:
                    </div>
                    <div className={cx('name')}>
                        {doc.data.owner.fullName || doc.data.owner.email}
                    </div>
                </div>
            </div>
            <div className={cx('chapters')}>
                <div className={cx('chapters-title')}>
                    Chapters
                </div>
                {
                    chapters.length > 0
                        ? (

                            <div className={cx('chapters-list')}>
                                {
                                    chapters.map((chapter) => {
                                        const sectionKey = chapter.id;

                                        return (
                                            <div
                                                key={sectionKey}
                                                className={cx('chapter-item')}
                                            >
                                                <RouteButton
                                                    title={chapter.name}
                                                    onClick={() => {
                                                        navigate(ROUTES.admin.chapters.id.get(chapter.id));
                                                    }}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        ) : (
                            <div className={cx('no-chapters')}>
                                No chapters
                            </div>
                        )
                }
            </div>
        </div>
    );
}

Document.propTypes = {
    //
};

type Props = InferProps<typeof Document.propTypes> & {
    doc: AdminDocumentListItem,
};

export default Document;
