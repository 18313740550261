import React, { useEffect } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { notify } from '@NOTIFICATION/Notificator';
import classnames from 'classnames/bind';
import { Typography } from '@mui/material';
import TagItem from '@PAGES/admin/edit-section/components/TagItem';
import Lock from '@COMPONENTS/SHARED/Lock';
import styles from './tags.module.scss';
import TagSection from '../tagSection';
import { TagsComponentProps } from './types';

const cx: CX = classnames.bind(styles);

function TagsComponent(props: TagsComponentProps) {
    const {
        tags,
        locked = false,
        tagQuery,
        apiParams,
        getAllTagsQuery,
        getTagsParams,
        addTagQuery,
        addTagParams,
        createAndAddTagQuery,
        createAndAddTagParams,
        sectionData,
    } = props;

    const [
        deleteTag,
        {
            isLoading: isDeleteTagLoading,
            isSuccess: isDeleteTagSuccess,
            isError: isDeleteTagError,
            error: deleteTagError,
            originalArgs: deleteTagOriginalArgs,
        },
    ] = tagQuery;

    useEffect(() => {
        if (isDeleteTagSuccess) {
            notify.info('Tag has been deleted', {
                toastId: 'delete-tag-from-section',
            });
        }
    }, [isDeleteTagSuccess]);

    useEffect(() => {
        if (isDeleteTagError) {
            if ((deleteTagError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'delete-tag-from-section-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'delete-tag-from-section-error',
                });
            }
        }
    }, [isDeleteTagError, deleteTagError]);

    return (
        <div className={cx('section-tags', 'container', 'custom-tag-container')}>
            <div className={cx('tag-warpper')}>
                <Typography>Tags</Typography>
                <div className={cx('tagsWrap')}>
                    {tags.length > 0 && (
                        <div className={cx('tags-list')}>
                            {tags.map((tag: any) => {
                                const key = tag.id;
                                const isProcessing = deleteTagOriginalArgs?.tagId === tag.id
                                    && isDeleteTagLoading;

                                return (
                                    <div
                                        key={key}
                                        className={cx('tag-item-wrapper')}
                                    >
                                        <TagItem
                                            tag={tag}
                                            isProcessing={isProcessing}
                                            locked={locked}
                                            onClick={() => {
                                                deleteTag({
                                                    ...apiParams,
                                                    tagId: tag.id,
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {locked && (
                        <div className={cx('lock-wrapper')}>
                            <Lock size="small" />
                        </div>
                    )}
                    {!locked && (
                        <TagSection
                            locked={locked}
                            getAllTagsQuery={getAllTagsQuery}
                            getTagsParams={getTagsParams}
                            addTagQuery={addTagQuery}
                            addTagParams={addTagParams}
                            createAndAddTagQuery={createAndAddTagQuery}
                            createAndAddTagParams={createAndAddTagParams}
                            sectionData={sectionData}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

TagsComponent.defaultProps = {
    locked: false,
};

export default TagsComponent;
