import React, {
    RefObject, useEffect, useMemo, useRef, useState,
} from 'react';
import { SingleValue } from 'react-select';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { notify } from '@NOTIFICATION/Notificator';
import classnames from 'classnames/bind';
import { useDeleteTag } from '@SERVICES';
import styles from './tagSection.module.scss';
import BasicSelect from '../inputs/select/inputs/BasicSelect';

const cx: CX = classnames.bind(styles);

function TagSection(props: any) {
    const {
        getAllTagsQuery, getTagsParams, addTagQuery, addTagParams, createAndAddTagQuery, createAndAddTagParams, sectionData,
    } = props;
    const { data: tagsData = { data: [] }, isLoading: isTagsLoading } = getAllTagsQuery(getTagsParams);

    const [addTag, { isSuccess, isError, error }] = addTagQuery;

    const [
        createAndAddTag,
        {
            isSuccess: newTagIsSuccess,
            isError: newTagIsError,
            error: newTagError,
        },
    ] = createAndAddTagQuery;

    const [
        deleteTag,
        {
            isSuccess: isDeleteTagSuccess,
            isError: isDeleteTagError,
            error: deleteTagError,
        },
    ] = useDeleteTag();

    const selectWrapperRef: RefObject<HTMLDivElement> = useRef(null);

    const [selectedTagId, setSelectedTagId] = useState<number | null>(null);

    useEffect(() => {
        if (isSuccess || newTagIsSuccess) {
            notify.info('New tag has been added', {
                toastId: 'add-tag-to-section',
            });

            setSelectedTagId(null);
        }
    }, [isSuccess, newTagIsSuccess]);

    useEffect(() => {
        if (isError || newTagIsError) {
            if (
                ((error || newTagError) as FetchBaseQueryError).status === 403
            ) {
                notify.error('Permission denied', {
                    toastId: 'add-tag-to-section-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'add-tag-to-section-error',
                });
            }
        }
    }, [isError, error, newTagIsError, newTagError]);

    useEffect(() => {
        if (isDeleteTagSuccess) {
            notify.info('Tag has been deleted', {
                toastId: 'delete-tag-from-chapter',
            });
        }
    }, [isDeleteTagSuccess]);

    useEffect(() => {
        if (isDeleteTagError) {
            if ((deleteTagError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'delete-tag-from-chapter-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'delete-tag-from-chapter-error',
                });
            }
        }
    }, [isDeleteTagError, deleteTagError]);

    const options = useMemo(
        () => tagsData.data
            .filter((tag: any) => !sectionData.tags.find((t: any) => t.id === tag.id))
            .map((tag: any) => ({
                ...tag,
                value: tag.id,
                label: tag.name,
            })),
        [sectionData, tagsData.data],
    );

    const value = useMemo(
        () => options.find((opt: any) => opt.id === selectedTagId) || null,
        [options, selectedTagId],
    );

    const handleNewTag = (params?: string) => {
        if (!params) return;
        createAndAddTag({
            ...createAndAddTagParams,
            tagName: params,
        });
    };

    const handleTagDelete = (params: { value: number; label: string }) => {
        deleteTag({
            id: params.value,
            documentId: sectionData.document.id,
        });
    };

    return (
        <div className={cx('section-tags-controls')}>
            <div ref={selectWrapperRef} className={cx('select-wrapper')}>
                <BasicSelect
                    title=""
                    placeholder="Add new tag ..."
                    isSearchable
                    options={options}
                    value={value}
                    menuPlacement="bottom"
                    isLoading={isTagsLoading}
                    onMenuOpen={() => {
                        if (selectWrapperRef.current) {
                            selectWrapperRef.current.style.zIndex = '5';
                        }
                    }}
                    onMenuClose={() => {
                        if (selectWrapperRef.current) {
                            selectWrapperRef.current.style.zIndex = 'unset';
                        }
                    }}
                    onChange={(val: SingleValue<any>) => {
                        if (val?.id) {
                            addTag({
                                ...addTagParams,
                                tagId: val.id,
                            });
                        }
                        setSelectedTagId(val?.id || null);
                    }}
                    onEnterPress={handleNewTag}
                    isDeleteable
                    onDelete={handleTagDelete}
                    customclassnames="loader-with-placeholder"
                />
            </div>
        </div>
    );
}

export default TagSection;
