import React from 'react';

import classnames from 'classnames/bind';

import {
    AdminSingleSectionDataVariant,
    AdminSingleSectionData, AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';

import VariantVendors from '@PAGES/admin/edit-section/components/VariantVendors';

import TagsComponent from '@COMPONENTS/COMMON/tags';
import {
    useAddTagToVariant, useCreateAndAddTagToVariant, useDeleteTagFromVariant, useGetAllTags,
} from '@SERVICES';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import styles from './VariantBodyFooter.module.scss';

const cx: CX = classnames.bind(styles);

function VariantBodyFooter(props: Props) {
    const { variant, sectionData, sectionMeta } = props;

    const {
        canManageDocumentTags,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const [deleteTag, deleteTagResult] = useDeleteTagFromVariant();
    const [addTagToSection, addTagToSectionResult] = useAddTagToVariant();
    const [createAndAddTagToVariant, createAndAddTagToVariantResult] = useCreateAndAddTagToVariant();

    return (
        <div className={cx('variant-body-footer')}>
            <div className={cx('tags-wrapper')}>
                <TagsComponent
                    locked={!canManageDocumentTags}
                    tags={variant.tags}
                    tagQuery={[deleteTag, deleteTagResult]}
                    apiParams={{
                        chapterId: sectionData.chapter.id,
                        sectionId: sectionData.id,
                        id: variant.id,
                    }}
                    getAllTagsQuery={useGetAllTags}
                    getTagsParams={{
                        documentId: sectionData.document.id,
                    }}
                    addTagQuery={[addTagToSection, addTagToSectionResult]}
                    addTagParams={{
                        chapterId: sectionData.chapter.id,
                        sectionId: sectionData.id,
                        id: variant.id,
                    }}
                    createAndAddTagQuery={[createAndAddTagToVariant, createAndAddTagToVariantResult]}
                    createAndAddTagParams={{
                        documentId: sectionData!.document!.id,
                        chapterId: sectionData!.chapter!.id,
                        sectionId: sectionData.id,
                        id: variant.id,
                    }}
                    sectionData={{ ...variant, document: sectionData.document }}
                />
            </div>
            <div className={cx('vendors-wrapper')}>
                <VariantVendors
                    variant={variant}
                    sectionData={sectionData}
                    sectionMeta={sectionMeta}
                />
            </div>
        </div>
    );
}

type Props = {
    variant: AdminSingleSectionDataVariant,
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default VariantBodyFooter;
