import React, { useEffect } from 'react';

import classnames from 'classnames/bind';

import { AdminSingleChapterData, AdminSingleChapterMeta } from '@INTERFACES/admin-api/chapters';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import useChapterNameModal from '@HOOKS/store/modals/useChapterNameModal';
import usePreviewChapterModal from '@HOOKS/store/modals/usePreviewChapterModal';

import ActionButton from '@COMPONENTS/COMMON/buttons/ActionButton/actionButton';
import HeaderImage from '@COMPONENTS/HEADERS/HeaderImage';
import { useUpdateChapter } from '@SERVICES';
import { notify } from '@NOTIFICATION/Notificator';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import styles from './ChapterName.module.scss';
import ChapterTags from '../ChapterTags';

const cx: CX = classnames.bind(styles);

function ChapterName(props: Props) {
    const {
        chapterData, chapterMeta, actionButton, bodyContent,
    } = props;

    const {
        canEditDocument,
        canGenerateDocument,
    } = useDocumentPermissions({
        documentOwnerId: chapterData.document.owner,
        permissions: chapterMeta.permissions,
    });

    const [
        updateChapter,
        {
            isSuccess, isError, error,
        },
    ] = useUpdateChapter();

    const {
        actions,
    } = useChapterNameModal(() => true);

    const {
        actions: previewChapterModalActions,
    } = usePreviewChapterModal(() => true);

    const onUpload = (imageBase: any) => {
        updateChapter({
            id: chapterData.id,
            documentId: chapterData.document.id,
            name: chapterData.name,
            body: chapterData.introductionText.body,
            isMandatory: chapterData.isMandatory,
            image: imageBase || null,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            notify.info('Chapter image has been updated', {
                toastId: 'update-chapter-image',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'update-chapter-image-permission-error',
                });
            } else {
                notify.error('Something wrong', {
                    toastId: 'update-chapter-image-error',
                });
            }
        }
    }, [isError, error]);

    return (
        <div className={cx('chapter-name')}>
            <div className={cx('chapter-header-wrapper')}>
                <HeaderImage imageTitle="Chapter" coverImage={chapterData.image} onUpload={onUpload} />
                <div className={cx('chapter-main-wrap')}>
                    <div className={cx('name')}>
                        {`Chapter - ${chapterData.name}`}

                        <div className={cx('action-button-wrapper')}>
                            {
                                canEditDocument
                                    && (
                                        <ActionButton
                                            buttonText="Rename"
                                            tooltipTitle="Edit chapter name"
                                            variant="outlined"
                                            handleClick={() => {
                                                actions.open({
                                                    chapterData,
                                                });
                                            }}
                                            icon="edit"
                                        />
                                    )
                            }

                            <ActionButton
                                buttonText="Preview"
                                variant="outlined"
                                handleClick={() => {
                                    previewChapterModalActions.open({
                                        chapterData,
                                        canGenerateDocument,
                                    });
                                }}
                                tooltipTitle="Chapter preview"
                                icon="preview"
                            />
                            {actionButton}
                        </div>
                    </div>
                    {bodyContent}
                    <div className={cx('body-wrapper')}>
                        <ChapterTags
                            chapterData={chapterData}
                            chapterMeta={chapterMeta}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}

ChapterName.defaultProps = {
    actionButton: null,
    bodyContent: null,
};

type Props = {
    chapterData: AdminSingleChapterData,
    chapterMeta: AdminSingleChapterMeta,
    actionButton?: React.ReactNode,
    bodyContent?: React.ReactNode,
};

export default ChapterName;
