import React from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';
import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import { BrandStep, BrandStepData } from '@REDUCERS';

import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';
import { useGetAllBrands } from '@SERVICES';

import StepSidebar from '@COMPONENTS/SIDEBARS/StepSidebar';
import Grid from '@COMPONENTS/SHARED/Grid';
import SquareButton from '@COMPONENTS/COMMON/buttons/SquareButton';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import ContentWithStickySidebar from '@COMPONENTS/SHARED/ContentWithStickySidebar';

import SearchbarInput from '@COMPONENTS/COMMON/inputs/input/SearchInput';
import styles from './Brand.module.scss';

const cx: CX = classnames.bind(styles);

function BrandPage() {
    const {
        actions: documentGenerationStepsActions,
        services: documentGenerationStepsServices,
    } = useDocumentGenerationSteps(() => true);

    const {
        data: brandsData = [],
        isLoading,
        isError,
        error,
    } = useGetAllBrands();
    const [brandName, setBrandName] = React.useState('');

    const navigate = useNavigate();

    const filterList = React.useMemo(() => {
        const lowerCaseBrandName = brandName.toLowerCase();
        const filteredBrands = brandsData.filter(({ name }: { name: string }) => name.toLowerCase().includes(lowerCaseBrandName));

        // Define the type for the accumulator
        type ServiceLevelMap = {
            [key: string]: typeof filteredBrands;
        };

        return filteredBrands.reduce<ServiceLevelMap>((acc, cur) => {
            const serviceLvl = cur.service_level || 'Default Service';

            if (!acc[serviceLvl]) {
                acc[serviceLvl] = [];
            }

            acc[serviceLvl].push(cur);

            return acc;
        }, {} as ServiceLevelMap);
    }, [brandName, brandsData]);

    function handleClick(id: number, name: string) {
        const data: BrandStepData = {
            id,
            name,
        };

        documentGenerationStepsActions.apply({
            id: GenerationSteps.BRAND,
            isComplete: true,
            data,
        });

        navigate(ROUTES.location.path);
    }

    function getHighlighted(id: number) {
        const step: BrandStep = documentGenerationStepsServices.getStepData(
            GenerationSteps.BRAND,
        );

        return step.data?.id === id;
    }

    return (
        <div className={cx('page')}>
            <div className="max-constraint center">
                <ContentWithStickySidebar
                    sidebar={<StepSidebar />}
                    offsetTop={80}
                    offsetBottom={100}
                >
                    <div className={cx('brand-header')}>
                        <h1>Brand</h1>
                        <div className={cx('brand-search')}>
                            <SearchbarInput
                                isLoading={false}
                                placeholder="Search inside brand"
                                value={brandName}
                                onChange={(val: string) => {
                                    setBrandName(val);
                                }}
                                onFocus={() => {}}
                                iconWrapper="icon-wapper"
                                inputWrapper="input-wapper"
                            />
                        </div>
                    </div>

                    <DataLoader
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    >
                        {Object.keys(filterList).map((serviceLvl) => (
                            <div key={serviceLvl}>
                                <h3 className={cx('title-wrapper')}>{serviceLvl}</h3>
                                <Grid
                                    hasSidebar
                                    additionalClassName={cx('brand-data')}
                                    gridChildClassName={cx('brand-child')}
                                >
                                    {filterList[serviceLvl].map((brand) => {
                                        const highlighted = getHighlighted(
                                            brand.id,
                                        );
                                        return (
                                            <SquareButton
                                                key={brand.id}
                                                highlighted={highlighted}
                                                title={brand.name}
                                                titleClassName={cx(
                                                    'title-wrap',
                                                )}
                                                onClick={() => handleClick(
                                                    brand.id,
                                                    brand.name,
                                                )}
                                                img={brand.logo_svg || ''}
                                            />

                                        );
                                    })}
                                </Grid>
                            </div>
                        ))}
                    </DataLoader>
                </ContentWithStickySidebar>
            </div>
        </div>
    );
}

export default BrandPage;
