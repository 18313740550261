import React from 'react';

interface IconSvgProps {
    iconName: string;
}

function IconSvg({ iconName }: IconSvgProps) {
    return (
        <img
            src={iconName}
            alt="icon"
            onError={(e) => {
                e.currentTarget.src = 'https://dev.mt-emea-dmt.apexdigital.online/media/logos/SpringHill_Suites_By_Marriott.svg';
            }}
        />
    );
}

export default IconSvg;
