import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import classnames from 'classnames/bind';
import { ReactComponent as ImagePreviewIcon } from '@ICONS/image-preview.svg';
import { ReactComponent as UploadImageIcon } from '@ICONS/upload-image-icon.svg';
import { ReactComponent as CloseIcon } from '@ICONS/close-icon.svg';
import { ReactComponent as PreviewIcon } from '@ICONS/image-preview-icon.svg';
import { FileWithPath } from 'react-dropzone';
import styles from './HeaderImage.module.scss';

const cx: CX = classnames.bind(styles);

function HeaderImage(props: HeaderImageProps) {
    const { imageTitle, onUpload, coverImage } = props;

    const [image, setImage] = useState<string | null>(coverImage);
    const [imageFile, setImageFile] = useState<File | null>(null);

    const readFile = (file: FileWithPath) => {
        const img = URL.createObjectURL(file);
        setImage(img);
        setImageFile(file);
    };

    return (
        <div className={cx('header-image-wrapper')}>
            <div className={cx('upload-image')}>
                {image ? (
                    <img
                        src={image}
                        alt=""
                    />
                ) : (
                    <div className={cx('icon-wrap')}>
                        {' '}
                        <ImagePreviewIcon />
                        <span>{`No ${imageTitle} Image`}</span>
                    </div>
                )}

            </div>
            <div className={cx('image-action-btns', {
                'upload-img-text': image,
            })}
            >
                {image && !coverImage && (
                    <Button
                        className={cx('imageUpload', {
                            'update-image': image,
                        })}
                        onClick={() => {
                            if (onUpload) {
                                onUpload(imageFile);
                            }
                        }}
                    >
                        Upload
                    </Button>
                )}
                <Button
                    className={cx('imageUpload', {
                        'update-image': image,
                    })}
                    component="label"
                    variant="contained"
                    startIcon={<UploadImageIcon />}
                >
                    <span className={cx('details-wrap')}>
                        Upload Image
                    </span>
                    <input
                        style={{
                            clip: 'rect(0 0 0 0)',
                            clipPath: 'inset(50%)',
                            height: 1,
                            overflow: 'hidden',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            whiteSpace: 'nowrap',
                            width: 1,
                        }}
                        type="file"
                        onChange={(event) => {
                            const { files } = event.target;
                            if (files && files.length > 0) {
                                readFile(files[0]);
                            }
                        }}
                        accept="image/*"
                    />
                </Button>
            </div>

            {image && (
                <Button
                    className={cx('closeIcon', {
                        'preview-icon': !coverImage,
                    })}
                    disabled={!coverImage}
                    onClick={() => {
                        if (onUpload) {
                            setImage(null);
                            onUpload(null);
                        }
                    }}
                >
                    {coverImage ? <CloseIcon /> : <PreviewIcon />}
                </Button>
            )}

        </div>
    );
}

HeaderImage.defaultProps = {
    onUpload: (imageUrl: any) => {},
    coverImage: null,
};

HeaderImage.propTypes = {
    imageTitle: PropTypes.string.isRequired,
    onUpload: PropTypes.func,
    coverImage: PropTypes.string,
};

interface HeaderImageProps {
    imageTitle: string;
    onUpload?: (blob: Blob | string | null) => void;
    coverImage?: any
}

export default HeaderImage;
