import React, { useEffect } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classnames from 'classnames/bind';

import {
    AdminSingleDocument, useDeleteTag, useGetAllTags,
} from '@SERVICES';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import { notify } from '@NOTIFICATION/Notificator';
import { Typography } from '@mui/material';
import TagItem from '@PAGES/admin/edit-section/components/TagItem';
import Lock from '@COMPONENTS/SHARED/Lock';
import styles from './DocumentTags.module.scss';
import DocumentAddTag from '../DocumentAddTag';

const cx: CX = classnames.bind(styles);

function DocumentTags(props: Props) {
    const { documentData } = props;

    const {
        canManageDocumentTags,
    } = useDocumentPermissions({
        documentOwnerId: documentData.data.owner.id,
        permissions: documentData.meta.permissions,
    });

    const {
        data: tagsData = { data: [] },
        isLoading,
    } = useGetAllTags({
        documentId: documentData.data.id,
    });

    const [
        deleteTag,
        {
            isLoading: isDeleteTagLoading,
            isSuccess: isDeleteTagSuccess,
            isError: isDeleteTagError,
            error: deleteTagError,
            originalArgs: deleteTagOriginalArgs,
        },
    ] = useDeleteTag();

    useEffect(() => {
        if (isDeleteTagSuccess) {
            notify.info('Tag has been deleted', {
                toastId: 'delete-tag-from-chapter',
            });
        }
    }, [isDeleteTagSuccess]);

    useEffect(() => {
        if (isDeleteTagError) {
            if ((deleteTagError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'delete-tag-from-chapter-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'delete-tag-from-chapter-error',
                });
            }
        }
    }, [isDeleteTagError, deleteTagError]);

    return (
        <div className={cx('document-tags')}>
            <div className={cx('tag-warpper')}>
                <Typography>
                    Document Tags
                </Typography>
                <div className={cx('tagsWrap')}>
                    {
                        tagsData.data.length > 0
                    && (
                        <div className={cx('tags-list')}>
                            {
                                tagsData.data.map((tag) => {
                                    const key = tag.id;
                                    const isProcessing = deleteTagOriginalArgs?.id === tag.id && isDeleteTagLoading;
                                    return (
                                        <div
                                            key={key}
                                            className={cx('tag-item-wrapper')}
                                        >
                                            <TagItem
                                                tag={tag}
                                                isProcessing={isLoading || isProcessing}
                                                locked={!canManageDocumentTags}
                                                onClick={() => {
                                                    deleteTag({
                                                        id: tag!.id,
                                                        documentId: documentData!.data!.id,
                                                    });
                                                }}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                    }
                    {!canManageDocumentTags ? (
                        <div className={cx('lock-wrapper')}>
                            <Lock size="small" />
                        </div>
                    ) : <DocumentAddTag documentId={documentData.data.id} />}
                </div>
            </div>
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocument,
};

export default DocumentTags;
