import React from 'react';
import IconButton from '@mui/material/IconButton';
import { components } from 'react-select';
import { ReactComponent as DeleteIcon } from '@ICONS/delete-icon.svg';

function DropdownTrash({
    children, onDelete, data, ...optionProps
}: DropdownTrashProps & any) {
    const handleDeleteClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (onDelete) {
            onDelete(data);
        }
    };

    return (
        <components.Option {...optionProps}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {children}
                <IconButton onClick={handleDeleteClick}>
                    <DeleteIcon />
                </IconButton>
            </div>
        </components.Option>
    );
}

interface DropdownTrashProps {
    children: React.ReactNode;
    onDelete?: (data: any) => void;
    data: any;
}

DropdownTrash.defaultProps = {
    onDelete: () => {},
};

export default DropdownTrash;
