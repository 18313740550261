import React, { MutableRefObject, useRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';

import classnames from 'classnames/bind';

import { AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import scrollToVariant from '@PAGES/admin/edit-section/helpers/scrollToVariant';

import RouteButton from '@COMPONENTS/COMMON/buttons/RouteButton';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import styles from './EditSectionSidebar.module.scss';

const cx: CX = classnames.bind(styles);

function EditSectionSidebar(props: Props) {
    const { view, sectionMeta } = props;

    const sections = sectionMeta?.sections || [];

    const { id } = useParams();
    const navigate = useNavigate();

    const timerRef: MutableRefObject<any> = useRef();

    function navigateToVariant(sectionId: number, variantId: number) {
        navigate(ROUTES.admin.sections.id.get(sectionId, variantId), {
            replace: true,
        });
    }

    const active = view === 'sections';

    return (
        <div
            className={cx('edit-section-sidebar', {
                active,
            })}
        >
            {
                active
                && (
                    <div className={cx('wrapper')}>
                        <div className={cx('sections-title', 'container-left')}>
                            Sections
                        </div>

                        <div className={cx('sections-list')}>
                            {
                                sections.map((section) => {
                                    const sectionKey = section.id;

                                    const isActiveSection = +id! === section.id;

                                    return (
                                        <div
                                            key={sectionKey}
                                            className={cx('section-list-item', {
                                                'is-active-section': isActiveSection,
                                            })}
                                        >
                                            <div className={cx('accent')} />
                                            <div className={cx('section-titile')}>
                                                <div className="container-left">
                                                    <RouteButton
                                                        title={section.name}
                                                        active={isActiveSection}
                                                        onClick={() => {
                                                            navigate(ROUTES.admin.sections.id.get(section.id));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="container-left">
                                                <div className={cx('variants')}>
                                                    <div className={cx('variants-list-title')}>
                                                        Variants
                                                    </div>
                                                    <div className={cx('variants-list')}>
                                                        {
                                                            section.variants.map((variant) => {
                                                                const variantKey = variant.id;

                                                                return (
                                                                    <button
                                                                        key={variantKey}
                                                                        type="button"
                                                                        className={cx('variant', 'clear-button')}
                                                                        onClick={() => {
                                                                            navigateToVariant(section.id, variant.id);

                                                                            if (+id! === section.id) {
                                                                                clearTimeout(timerRef.current);

                                                                                timerRef.current = setTimeout(() => {
                                                                                    scrollToVariant(variant.id);
                                                                                }, 150);
                                                                            }
                                                                        }}
                                                                    >
                                                                        {variant.name}
                                                                    </button>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}

EditSectionSidebar.propTypes = {
    view: PropTypes.oneOf(['options', 'sections']).isRequired,
};

type Props = InferProps<typeof EditSectionSidebar.propTypes> & {
    sectionMeta: AdminSingleSectionMeta,
};

export default EditSectionSidebar;
