import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';
import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import { OptionsStep, OptionsStepData } from '@REDUCERS';
import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';

import TabRadio from '@COMPONENTS/COMMON/inputs/radio/TabRadio';
import WideButton from '@COMPONENTS/COMMON/buttons/WideButton';
import BasicInput from '@COMPONENTS/COMMON/inputs/input/BasicInput';
import StepSidebar from '@COMPONENTS/SIDEBARS/StepSidebar';
import ContentWithStickySidebar from '@COMPONENTS/SHARED/ContentWithStickySidebar';

import styles from './Options.module.scss';

const cx: CX = classnames.bind(styles);

export enum GenerationMode {
    FULL = 1,
    PARTIAL = 2,
}

export const generationModes = [
    {
        id: GenerationMode.FULL,
        name: 'Full Document',
    },
    {
        id: GenerationMode.PARTIAL,
        name: 'Partial Extraction',
    },
];

function OptionsPage() {
    const {
        actions: documentGenerationStepsActions,
        services: documentGenerationStepsServices,
    } = useDocumentGenerationSteps(() => true);

    const step: OptionsStep = documentGenerationStepsServices.getStepData(GenerationSteps.OPTIONS);

    const [
        generationMode,
        setGenerationMode,
    ] = useState(step.data?.id || GenerationMode.FULL);

    const [
        propertyName,
        setPropertyName,
    ] = useState(step.data?.propertyName || '');

    const navigate = useNavigate();

    return (
        <div className={cx('page')}>
            <div className="max-constraint center">
                <ContentWithStickySidebar
                    sidebar={<StepSidebar />}
                    offsetTop={80}
                    offsetBottom={100}
                >
                    <h1>Options</h1>
                    <div className={cx('content')}>
                        <div className={cx('tabs-wrapper')}>
                            <TabRadio
                                title="What kind of document do you want to generate?"
                                items={generationModes}
                                activeId={generationMode}
                                onChange={(id: number) => {
                                    setGenerationMode(id);
                                }}
                            />
                        </div>

                        <div className={cx('input-wrapper')}>
                            <BasicInput
                                value={propertyName}
                                label="Property Name (Optional)"
                                onChange={(val: string) => {
                                    setPropertyName(val);
                                }}
                            />
                        </div>
                    </div>
                </ContentWithStickySidebar>
            </div>

            <WideButton
                title="Next step"
                onClick={() => {
                    const data: OptionsStepData = {
                        id: generationMode,
                        propertyName,
                    };

                    documentGenerationStepsActions.apply({
                        id: GenerationSteps.OPTIONS,
                        isComplete: true,
                        data,
                    });

                    navigate(ROUTES.selectContent.path);
                }}
            />
        </div>
    );
}

export default OptionsPage;
