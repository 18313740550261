import React, { Suspense } from 'react';
import classnames from 'classnames/bind';
import IconSvg from '@COMPONENTS/SHARED/Iconvg/IconSvg';
import styles from './iconLogo.module.scss';

const cx: CX = classnames.bind(styles);

interface IconLoaderProps {
    iconName: string;
}

function IconLoader(props: IconLoaderProps) {
    const { iconName } = props;

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className={cx('image-wrap')}>
                <IconSvg iconName={iconName} />
            </div>
        </Suspense>
    );
}

export default IconLoader;
