enum GenerationSteps {
    DOCUMENT = '/document',
    TYPE = '/type',
    BRAND = '/brand',
    LOCATION = '/location',
    OPTIONS = '/options',
    SELECT_CONTENT = '/select-content',
    PREVIEW = '/preview',
    DOWNLOAD_DOCUMENT = '/download-document',
}

export default GenerationSteps;
