import { DOCUMENT_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';

export const permissions = [
    {
        id: 1,
        name: 'generate',
        codename: DOCUMENT_PERMISSIONS.CAN_GENERATE_DOCUMENT,
    },
    {
        id: 2,
        name: 'edit',
        codename: DOCUMENT_PERMISSIONS.CAN_EDIT_DOCUMENT,
    },
    {
        id: 3,
        name: 'delete',
        codename: DOCUMENT_PERMISSIONS.CAN_DELETE_DOCUMENT,
    },
    {
        id: 4,
        name: 'publish',
        codename: DOCUMENT_PERMISSIONS.CAN_PUBLISH_DOCUMENT,
    },
    {
        id: 5,
        name: 'manage tags',
        codename: DOCUMENT_PERMISSIONS.CAN_MANAGE_DOCUMENT_TAGS,
    },
    {
        id: 6,
        name: 'manage vendors',
        codename: DOCUMENT_PERMISSIONS.CAN_MANAGE_DOCUMENT_VENDORS,
    },
];
