import React from 'react';
import {
    Autocomplete, TextField, AutocompleteProps,
    Avatar,
} from '@mui/material';
import { ReactComponent as SearchIcon } from '@ICONS/search-icon.svg';
import classNames from 'classnames/bind';
import styles from './AutoCompleteWithIcon.module.scss';

export interface OptionWithIcon<T> {
    value: T;
    label: string;
    icon: React.ReactNode;
}

const cx = classNames.bind(styles);

interface AutoCompleteWithIconProps<T> extends Omit<
AutocompleteProps<OptionWithIcon<T>, false, false, false>,
'renderInput' | 'renderOption'
> {
    options: OptionWithIcon<T>[];
    value: OptionWithIcon<T> | null;
    onChange: (event: React.SyntheticEvent, value: OptionWithIcon<T> | null) => void;
    onInputChange?: (event: React.SyntheticEvent, value: string, reason: string) => void;
}

function AutoCompleteWithIcon<T>({
    options,
    value,
    onChange,
    onInputChange,
}: AutoCompleteWithIconProps<T>) {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onInputChange) {
            onInputChange(event, event.target.value, 'input');
        }
    };

    return (
        <Autocomplete
            className={cx('autocomplete-with-icon')}
            sx={{ width: 274 }}
            options={options}
            value={value}
            onChange={onChange}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(renderprops, option) => (
                <li {...renderprops} className={cx('autocomplete-option')}>
                    <div className={cx('option-content')}>
                        {option.icon}
                        <span>{option.label}</span>
                    </div>
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Search User"
                    onChange={handleInputChange}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: value ? (
                            <Avatar
                                className={cx('selected-avatar')}
                                src={typeof value.icon === 'string' ? value.icon : undefined}
                            >
                                {typeof value.icon !== 'string' && value.icon}
                            </Avatar>
                        ) : null,
                        endAdornment: (
                            <SearchIcon />
                        ),
                    }}
                />
            )}
            classes={{ paper: cx('autocomplete-menu') }}
        />
    );
}

AutoCompleteWithIcon.defaultProps = {
    onInputChange: () => {},
};

export default AutoCompleteWithIcon;
