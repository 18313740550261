import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { useGetDocumentByIdAdmin } from '@SERVICES';

import DESIGN from '@CONSTANTS/DESIGN.constant';

import useDocumentNameModal from '@HOOKS/store/modals/useDocumentNameModal';
import useNewChapterModal from '@HOOKS/store/modals/useNewChapterModal';
import usePublishDocumentModal from '@HOOKS/store/modals/usePublishDocumentModal';
import useDeleteChapterModal from '@HOOKS/store/modals/useDeleteChapterModal';
import useDeleteTagModal from '@HOOKS/store/modals/useDeleteTagModal';
import usePreviewDocumentModal from '@HOOKS/store/modals/usePreviewDocumentModal';
import useNewTagModal from '@HOOKS/store/modals/useNewTagModal';
import useTagNameModal from '@HOOKS/store/modals/useTagNameModal';
import useOrderChaptersDrawer from '@HOOKS/store/drawers/useOrderChaptersDrawer';

import Content from '@PAGES/admin/document/components/Content';

import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import ContentWithFullHeightSidebar from '@COMPONENTS/SHARED/ContentWithFullHeightSidebar';
import DocumentSidebar from '@COMPONENTS/SIDEBARS/DocumentSidebar';

import styles from './Document.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'] + DESIGN['admin-breadcrumbs-height'];

function DocumentPage() {
    const params = useParams();

    const { id } = params;

    const {
        data: documentData, isLoading, isFetching, isError, error,
    } = useGetDocumentByIdAdmin(+id!);

    const {
        actions: documentNameModalActions,
    } = useDocumentNameModal(() => true);

    const {
        actions: newChapterModalActions,
    } = useNewChapterModal(() => true);

    const {
        actions: previewDocumentModalActions,
    } = usePreviewDocumentModal(() => true);

    const {
        actions: publishDocumentModalActions,
    } = usePublishDocumentModal(() => true);

    const {
        actions: deleteChapterModalActions,
    } = useDeleteChapterModal(() => true);

    const {
        actions: deleteTagModalActions,
    } = useDeleteTagModal(() => true);

    const {
        actions: newTagModalActions,
    } = useNewTagModal(() => true);

    const {
        actions: tagNameModalActions,
    } = useTagNameModal(() => true);

    const {
        actions: orderChaptersDrawerActions,
    } = useOrderChaptersDrawer(() => true);

    // CLOSE MODALS AND DRAWERS
    useEffect(() => () => {
        documentNameModalActions.close();
        newChapterModalActions.close();
        publishDocumentModalActions.close();
        previewDocumentModalActions.close();
        deleteChapterModalActions.close();
        deleteTagModalActions.close();
        newTagModalActions.close();
        tagNameModalActions.close();
        orderChaptersDrawerActions.close();
    }, [
        id,
        documentNameModalActions,
        newChapterModalActions,
        publishDocumentModalActions,
        previewDocumentModalActions,
        deleteChapterModalActions,
        deleteTagModalActions,
        newTagModalActions,
        tagNameModalActions,
        orderChaptersDrawerActions,
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    if (isError && (error as FetchBaseQueryError)?.status === 404) {
        return (
            <div className={cx('document-not-found', 'container')}>
                404 Document not found
            </div>
        );
    }

    return (
        <div className={cx('page')}>
            <div className="max-constraint center">
                <ContentWithFullHeightSidebar
                    top={top}
                    sidebar={(
                        <DocumentSidebar
                            documents={documentData?.meta?.documents || []}
                            chapters={documentData?.data?.chapters || []}
                        />
                    )}
                >
                    <DataLoader
                        isLoading={isLoading}
                        isFetching={isFetching}
                        isError={isError}
                        error={error}
                    >
                        <Content
                            key={documentData?.data?.id || 0}
                            documentData={documentData!}
                        />
                    </DataLoader>
                </ContentWithFullHeightSidebar>
            </div>
        </div>
    );
}

export default DocumentPage;
