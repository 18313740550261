import React from 'react';

import { useLocation } from 'react-router-dom';

import classnames from 'classnames/bind';

import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';

import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';
import {
    BrandStep,
    DocumentStep,
    OptionsStep,
    TypeStep,
} from '@REDUCERS';

import { generationModes } from '@PAGES/manager/options/Options.page';

import styles from './StepSidebar.module.scss';

const cx: CX = classnames.bind(styles);

const steps = [
    GenerationSteps.DOCUMENT,
    GenerationSteps.TYPE,
    GenerationSteps.BRAND,
    GenerationSteps.LOCATION,
    GenerationSteps.OPTIONS,
];

function StepSidebar() {
    const {
        services,
    } = useDocumentGenerationSteps();

    const location = useLocation();

    const documentStep: DocumentStep = services.getStepData(GenerationSteps.DOCUMENT);
    const typeStep: TypeStep = services.getStepData(GenerationSteps.TYPE);
    const brandStep: BrandStep = services.getStepData(GenerationSteps.BRAND);
    const locationStep: any = services.getStepData(GenerationSteps.LOCATION);
    const optionsStep: OptionsStep = services.getStepData(GenerationSteps.OPTIONS);

    const path = (step: GenerationSteps) => {
        let activeDot = false;
        let activeLine = false;

        const activeStepIndex = steps.indexOf(location.pathname as GenerationSteps);
        const currentStepIndex = steps.indexOf(step);

        if (activeStepIndex > currentStepIndex) {
            activeLine = true;
        }

        if (activeStepIndex >= currentStepIndex) {
            activeDot = true;
        }

        return (
            <>
                <div
                    className={cx('dot', {
                        current: step === location.pathname,
                        active: activeDot,
                    })}
                />
                <div
                    className={cx('line', {
                        active: activeLine,
                    })}
                />
            </>
        );
    };

    return (
        <div className={cx('step-sidebar')}>
            <div className={cx('rows')}>
                <div className={cx('row', 'document')}>
                    {path(GenerationSteps.DOCUMENT)}

                    <div className={cx('title')}>
                        Document
                    </div>
                    <div className={cx('values')}>
                        {documentStep.data?.name || '-'}
                    </div>
                </div>
                <div className={cx('row', 'type')}>
                    {path(GenerationSteps.TYPE)}

                    <div className={cx('title')}>
                        Type
                    </div>
                    <div className={cx('values')}>
                        {typeStep.data?.name || '-'}
                    </div>
                </div>
                <div className={cx('row', 'brand')}>
                    {path(GenerationSteps.BRAND)}

                    <div className={cx('title')}>
                        Brand
                    </div>
                    <div className={cx('values')}>
                        {brandStep.data?.name || '-'}
                    </div>
                </div>
                <div className={cx('row', 'location')}>
                    {path(GenerationSteps.LOCATION)}

                    <div className={cx('title')}>
                        Location
                    </div>
                    <div className={cx('values')}>
                        {locationStep.data?.name || '-'}
                    </div>
                </div>
                <div className={cx('row', 'options')}>
                    {path(GenerationSteps.OPTIONS)}

                    <div className={cx('title')}>
                        Options
                    </div>
                    <div className={cx('values')}>
                        <div className={cx('value')}>
                            {optionsStep.isComplete ? generationModes.find((m) => m.id === optionsStep.data.id)!.name : '-'}
                        </div>
                        {
                            optionsStep.data.propertyName
                            && (
                                <div className={cx('value', 'property-name')}>
                                    <div className={cx('property-name-title')}>
                                        Property Name
                                    </div>
                                    <div className={cx('property-name-value')}>
                                        {optionsStep.data.propertyName}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(StepSidebar, () => true);
