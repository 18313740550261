import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';
import {
    AdminSingleChapterMetaChapter,
    ChapterSection,
} from '@INTERFACES/admin-api/chapters';

import Accordions from '@COMPONENTS/COMMON/accordion/accordion';
import styles from './ChaptersView.module.scss';

const cx: CX = classnames.bind(styles);

function ChaptersView(props: Props) {
    const { chapters, sections } = props;

    const { id } = useParams();
    const navigate = useNavigate();

    const activeChapter = chapters.find(({ id: chId }) => chId === Number(id));

    const content = (
        <ul>
            {sections?.map(({ name: secName, id: secId }: any) => (
                <li key={secName}>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            navigate(ROUTES.admin.sections.id.get(secId));
                        }}
                        className={cx('charpter-link')}
                    >
                        {secName}
                    </div>
                </li>
            ))}
        </ul>
    );

    return (
        <div className={cx('chapters-view')}>
            <div className={cx('chapters-title')}>{activeChapter?.name}</div>

            <div className={cx('documents-list')}>
                <Accordions
                    key={activeChapter?.id}
                    title={activeChapter?.name || ''}
                    detailsContain={content}
                    defaultExpanded
                    classNames="first-accordion"
                />
            </div>
        </div>
    );
}

type Props = {
    chapters: AdminSingleChapterMetaChapter[];
    sections: ChapterSection[];
};

export default ChaptersView;
