import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import {
    ContentChapter, ContentTag, ContentVariant,
} from '@INTERFACES/api/content';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import { extractSectionsTags } from '../admin-api/chapters.admin-api';

type ContentApiResponse = {
    data: {
        id: number;
        name: string;
        chapters: {
            id: number;
            name: string;
            order: number;
            has_body: boolean;
            is_mandatory: boolean;
            tags: {
                id: number;

                name: string;
            }[];
            sections: {
                id: number;
                name: string;
                order: number;
                has_body: boolean;
                is_mandatory: boolean;
                tags: {
                    id: number;
                    name: string;
                }[];
                variants: {
                    id: number;
                    name: string;
                    is_default: boolean;
                    brands: {
                        id: number;
                        name: string;
                    }[];
                    regions: {
                        id: number;
                        name: string;
                    }[];
                    areas: {
                        id: number;
                        name: string;
                    }[];
                    countries: {
                        id: number;
                        name: string;
                    }[];
                    property_types: {
                        id: number;
                        name: string;
                    }[];
                    tags: {
                        id: number;
                        name: string;
                    }[];
                }[];
            }[];
        }[];
    };
};

type Id = number | undefined;

type GetContentParams = {
    document: Id;
    brand: Id;
    country: Id;
    type: Id;
};

const SortById = (a: any, b: any) => a.id - b.id;
const SortByOrder = (a: any, b: any) => a.order - b.order;

type ReturnType = {
    data: ContentChapter[];
    meta: {
        tags: ContentTag[];
    }
};

function transformContentResponse(raw: ContentApiResponse, arg: GetContentParams): ReturnType {
    const data: ContentChapter[] = raw.data.chapters.map((chapter) => ({
        id: chapter.id,
        name: chapter.name,
        order: chapter.order,
        hasBody: chapter.has_body,
        isMandatory: chapter.is_mandatory,
        tags: chapter.tags.map((tag) => ({
            id: tag.id,
            name: tag.name,
        })).sort(SortById),
        sections: chapter.sections.map((section) => ({
            id: section.id,
            name: section.name,
            order: section.order,
            hasBody: section.has_body,
            isMandatory: section.is_mandatory,
            tags: section.tags.map((tag) => ({
                id: tag.id,
                name: tag.name,
            })).sort(SortById),
            variants: section.variants.map((variant) => ({
                id: variant.id,
                name: variant.name,
                isDefault: variant.is_default,
                brands: variant.brands,
                regions: variant.regions,
                areas: variant.areas,
                countries: variant.countries,
                propertyTypes: variant.property_types,
                tags: variant.tags.map((tag) => ({
                    id: tag.id,
                    name: tag.name,
                })).sort(SortById),
            })),
        })),
    }));

    const tags: any = extractSectionsTags(raw.data.chapters.reduce((chapterAcc: any, chapter: any) => {
        const { sections } = chapter;

        return [
            ...chapterAcc,
            ...chapter.tags,
            ...sections.reduce((sectionAcc: any, section: any) => {
                const { variants } = section;

                return [
                    ...sectionAcc,
                    ...section.tags,
                    ...variants.reduce((innerAcc: any, variant: any) => {
                        const { tags: t } = variant;

                        return [...innerAcc, ...t];
                    }, []),
                ];
            }, []),
        ];
    }, []));

    function variantPriority(variant: ContentVariant, params: GetContentParams) {
        let count = 0;

        if (params.type !== undefined && variant.propertyTypes.map((type) => type.id).includes(params.type)) {
            count += 1;
        }

        if (params.brand !== undefined && variant.brands.map((brand) => brand.id).includes(params.brand)) {
            count += 1;
        }

        if (params.country !== undefined && variant.countries.map((country) => country.id).includes(params.country)) {
            count += 1;
        }

        if (variant.isDefault) {
            return -1000;
        }

        return count;
    }

    data.sort(SortByOrder).map(
        (chapter) => chapter.sections.sort(SortByOrder).map(
            (section) => section.variants.sort((a, b) => variantPriority(b, arg) - variantPriority(a, arg)),
        ),
    );

    return {
        data,
        meta: {
            tags,
        },
    };
}

export const contentApi = createApi({
    reducerPath: 'content-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getContent: builder.query<ReturnType, GetContentParams>({
            query: (params: GetContentParams) => ({
                url: `/content/${params.document}/`,
                params: {
                    brand: params.brand,
                    country: params.country,
                    property_type: params.type,
                },
            }),
            transformResponse(raw: ContentApiResponse, _, arg: GetContentParams) {
                return transformContentResponse(raw, arg);
            },
        }),
    }),
});

export const {
    useGetContentQuery: useGetContent,
} = contentApi;
