import React from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';
import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import { TypeStep, TypeStepData } from '@REDUCERS';
import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';
import { useGetAllProperties } from '@SERVICES';

import Grid from '@COMPONENTS/SHARED/Grid';
import SquareButton from '@COMPONENTS/COMMON/buttons/SquareButton';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import ContentWithStickySidebar from '@COMPONENTS/SHARED/ContentWithStickySidebar';
import StepSidebar from '@COMPONENTS/SIDEBARS/StepSidebar';

import styles from './Property.module.scss';

const cx: CX = classnames.bind(styles);

function PropertyPage() {
    const {
        actions: documentGenerationStepsActions,
        services: documentGenerationStepsServices,
    } = useDocumentGenerationSteps(() => true);

    const {
        data: propertiesData = [], isLoading, isError, error,
    } = useGetAllProperties();

    const navigate = useNavigate();

    function handleClick(id: number, name: string) {
        const data: TypeStepData = {
            id,
            name,
        };

        documentGenerationStepsActions.apply({
            id: GenerationSteps.TYPE,
            isComplete: true,
            data,
        });

        navigate(ROUTES.brand.path);
    }

    function getHighlighted(id: number) {
        const step: TypeStep = documentGenerationStepsServices.getStepData(GenerationSteps.TYPE);

        return step.data?.id === id;
    }

    return (
        <div className={cx('page')}>
            <div className="max-constraint center">
                <ContentWithStickySidebar
                    sidebar={<StepSidebar />}
                    offsetTop={80}
                    offsetBottom={100}
                >
                    <h1>Type</h1>
                    <DataLoader
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    >
                        <Grid
                            hasSidebar
                        >
                            {
                                propertiesData.map((property) => {
                                    const key = property.id;

                                    const highlighted = getHighlighted(property.id);

                                    return (
                                        <SquareButton
                                            key={key}
                                            highlighted={highlighted}
                                            title={property.name}
                                            onClick={() => handleClick(property.id, property.name)}
                                        />
                                    );
                                })
                            }
                        </Grid>
                    </DataLoader>
                </ContentWithStickySidebar>
            </div>
        </div>
    );
}

export default PropertyPage;
