import React, { useRef, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { MultiValue } from 'react-select';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import RoundedCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/RoundedCheckbox';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';
import BasicMultiSelect from 'src/view/COMPONENTS/COMMON/inputs/select/inputs/BasicMultiSelect';

import { TextField } from '@mui/material';
import styles from './VariantForm.module.scss';

const cx: CX = classnames.bind(styles);

const defaultOptions = {
    brands: [],
    regions: [],
    areas: [],
    countries: [],
    propertyTypes: [],
};

export type VariantFormValues = {
    isDefault: boolean;
    name: string;
    brands: Property[];
    regions: Property[];
    areas: Property[];
    countries: Property[];
    propertyTypes: Property[];
};

type Val = 'brands' | 'regions' | 'areas' | 'countries' | 'propertyTypes';

type PropertyOption = {
    label: string;
    value: number;
};

function getValue(sectionMeta: AdminSingleSectionMeta, properties: Property[], val: Val): PropertyOption[] {
    if (properties.length === 0) return [];

    const metaOptions = sectionMeta?.options || defaultOptions;

    const options = metaOptions[val];

    // TODO add ts-reset package
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return properties.map((p) => {
        const findOption = options.find((o) => o.id === p.id);

        if (!findOption) {
            return null;
        }

        return {
            value: findOption.id,
            label: findOption.name,
        };
    }).filter(Boolean);
}

function VariantForm(props: Props) {
    const {
        locked,
        buttonTitle,
        isLoading,
        newVariant,
        defaultValues,
        sectionMeta,
        onSave,
        onMenuOpen,
        onMenuClose,
        handleToggleDefault,
    } = props;

    const metaOptions = sectionMeta.options || defaultOptions;

    const { isDefault } = defaultValues;

    const [options, setOptions] = useState({
        brands: defaultValues.brands,
        regions: defaultValues.regions,
        areas: defaultValues.areas,
        countries: defaultValues.countries,
        propertyTypes: defaultValues.propertyTypes,
    });

    const [validationError, setValidationError] = useState<boolean>(false);
    const variantNameRef = useRef<HTMLInputElement>(null);

    return (
        <div className={cx('variant-form')}>
            <div className={cx('is-default-variant')}>
                <div className={cx('checkbox-title')}>
                    Default variant
                </div>
                <RoundedCheckbox
                    color={COLOR['marriott-orange']}
                    checked={isDefault}
                    onChange={handleToggleDefault}
                    disabled={defaultValues.isDisable}
                />
            </div>
            <div className={cx('selects')}>
                {/* varient name */}
                {newVariant && (
                    <div className={cx('new-variant')}>
                        <span>Variant Name: </span>
                        <TextField
                            placeholder="Enter variant name"
                            inputRef={variantNameRef}
                            error={validationError}
                            helperText={validationError ? 'Name is required' : ''}
                        />
                    </div>
                )}
                <div className={cx('select-wrapper')}>
                    <BasicMultiSelect
                        title="Brands"
                        isDisabled={isDefault}
                        placeholder="Choose Brands"
                        options={metaOptions.brands.map((data) => ({
                            ...data,
                            value: data.id,
                            label: data.name,
                        }))}
                        value={getValue(sectionMeta, options.brands, 'brands')}
                        onChange={(val: MultiValue<PropertyOption>) => {
                            setOptions((opts) => ({
                                ...opts,
                                brands: val.map((p) => ({ id: p.value })),
                            }));
                        }}
                        onMenuOpen={() => onMenuOpen()}
                        onMenuClose={() => onMenuClose()}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicMultiSelect
                        title="Regions"
                        isDisabled={isDefault}
                        placeholder="Choose Regions"
                        options={metaOptions.regions.map((data) => ({
                            ...data,
                            value: data.id,
                            label: data.name,
                        }))}
                        value={getValue(sectionMeta, options.regions, 'regions')}
                        onChange={(val: MultiValue<PropertyOption>) => {
                            setOptions((opts) => ({
                                ...opts,
                                regions: val.map((p) => ({ id: p.value })),
                            }));
                        }}
                        onMenuOpen={() => onMenuOpen()}
                        onMenuClose={() => onMenuClose()}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <div className={cx('select-wrapper')}>
                        <BasicMultiSelect
                            title="Areas"
                            isDisabled={isDefault}
                            placeholder="Choose Areas"
                            options={metaOptions.areas.map((data) => ({
                                ...data,
                                value: data.id,
                                label: data.name,
                            }))}
                            value={getValue(sectionMeta, options.areas, 'areas')}
                            onChange={(val: MultiValue<PropertyOption>) => {
                                setOptions((opts) => ({
                                    ...opts,
                                    areas: val.map((p) => ({ id: p.value })),
                                }));
                            }}
                            onMenuOpen={() => onMenuOpen()}
                            onMenuClose={() => onMenuClose()}
                        />
                    </div>
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicMultiSelect
                        title="Countries"
                        isDisabled={isDefault}
                        placeholder="Choose Countries"
                        options={metaOptions.countries.map((data) => ({
                            ...data,
                            value: data.id,
                            label: data.name,
                        }))}
                        value={getValue(sectionMeta, options.countries, 'countries')}
                        onChange={(val: MultiValue<PropertyOption>) => {
                            setOptions((opts) => ({
                                ...opts,
                                countries: val.map((p) => ({ id: p.value })),
                            }));
                        }}
                        onMenuOpen={() => onMenuOpen()}
                        onMenuClose={() => onMenuClose()}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicMultiSelect
                        title="Types"
                        isDisabled={isDefault}
                        placeholder="Choose Property Types"
                        options={metaOptions.propertyTypes.map((data) => ({
                            ...data,
                            value: data.id,
                            label: data.name,
                        }))}
                        value={getValue(sectionMeta, options.propertyTypes, 'propertyTypes')}
                        onChange={(val: MultiValue<PropertyOption>) => {
                            setOptions((opts) => ({
                                ...opts,
                                propertyTypes: val.map((p) => ({ id: p.value })),
                            }));
                        }}
                        onMenuOpen={() => onMenuOpen()}
                        onMenuClose={() => onMenuClose()}
                    />
                </div>
            </div>
            <BasicButton
                locked={locked}
                title={buttonTitle}
                isProcessing={isLoading}
                style={{
                    height: 40,
                    width: '100%',
                    fontSize: 14,
                    backgroundColor: COLOR['app-green'],
                }}
                onClick={() => {
                    const value = variantNameRef.current?.value?.trim();
                    if (newVariant && !value) {
                        return setValidationError(true);
                    }
                    onSave({
                        isDefault,
                        ...(newVariant && { name: value }),
                        brands: options.brands,
                        regions: options.regions,
                        areas: options.areas,
                        countries: options.countries,
                        propertyTypes: options.propertyTypes,
                    });
                }}
            />
        </div>
    );
}

VariantForm.defaultProps = {
    onMenuOpen: () => {
    },
    onMenuClose: () => {
    },
    newVariant: false,
};

VariantForm.propTypes = {
    locked: PropTypes.bool.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onMenuOpen: PropTypes.func,
    onMenuClose: PropTypes.func,
    handleToggleDefault: PropTypes.func.isRequired,
    newVariant: PropTypes.bool,
};

type Property = {
    id: number;
};

type Props = InferProps<typeof VariantForm.propTypes> & typeof VariantForm.defaultProps & {
    defaultValues: {
        isDefault: boolean;
        isDisable?: boolean;
        brands: Property[];
        regions: Property[];
        areas: Property[];
        countries: Property[];
        propertyTypes: Property[];
    };

    sectionMeta: AdminSingleSectionMeta;
};

export default VariantForm;
