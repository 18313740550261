import React from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';
import DESIGN from '@CONSTANTS/DESIGN.constant';
import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import { useGetPreview } from '@SERVICES';
import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';

import {
    BrandStep,
    CountryStep,
    DocumentStep,
    OptionsStep,
    PreviewStepData,
    SelectContentStep,
    TypeStep,
} from '@REDUCERS';

import { GenerationMode } from '@PAGES/manager/options/Options.page';

import ContentWithFullHeightSidebar from '@COMPONENTS/SHARED/ContentWithFullHeightSidebar';
import PreviewSidebar from '@COMPONENTS/SIDEBARS/PreviewSidebar';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import Content from '@PAGES/manager/preview/components/Content';
import WideButton from '@COMPONENTS/COMMON/buttons/WideButton';

import styles from './Preview.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'] + DESIGN['step-breadcrumbs-height'];

const defaultData = {
    data: [],
    meta: {
        document: null,
    },
};

function PreviewPage() {
    const {
        actions: documentGenerationStepsActions,
        services: documentGenerationStepsServices,
    } = useDocumentGenerationSteps(() => true);

    const documentStep: DocumentStep = documentGenerationStepsServices.getStepData(GenerationSteps.DOCUMENT);
    const typeStep: TypeStep = documentGenerationStepsServices.getStepData(GenerationSteps.TYPE);
    const brandStep: BrandStep = documentGenerationStepsServices.getStepData(GenerationSteps.BRAND);
    const countryStep: CountryStep = documentGenerationStepsServices.getStepData(GenerationSteps.LOCATION);
    const optionsStep: OptionsStep = documentGenerationStepsServices.getStepData(GenerationSteps.OPTIONS);
    const selectContentStep: SelectContentStep = documentGenerationStepsServices.getStepData(GenerationSteps.SELECT_CONTENT);

    const isPartial = optionsStep!.data.id === GenerationMode.PARTIAL;

    const {
        data: previewData = defaultData, isLoading, isError, error,
    } = useGetPreview({
        documentId: documentStep.data?.id,
        variant: selectContentStep.data?.variantsIds || [],
        section: selectContentStep.data?.sectionsIds || [],
        chapter: selectContentStep.data?.chaptersIds || [],
        brand: brandStep.data?.id,
        country: countryStep.data?.id,
        type: typeStep.data?.id,
    });

    const navigate = useNavigate();

    return (
        <div className={cx('page')}>
            <div className="max-constraint center">
                <ContentWithFullHeightSidebar
                    sidebar={(
                        <PreviewSidebar
                            previewData={previewData.data}
                        />
                    )}
                    top={top}
                >
                    <DataLoader
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    >
                        <div className="container">
                            <h1>Preview</h1>
                        </div>
                        <Content
                            previewData={previewData.data}
                            isPartial={isPartial}
                        />
                    </DataLoader>
                </ContentWithFullHeightSidebar>
            </div>
            <WideButton
                withSidebar
                title="Approve"
                onClick={() => {
                    const data: PreviewStepData = {
                        approved: true,
                    };

                    documentGenerationStepsActions.apply({
                        id: GenerationSteps.PREVIEW,
                        isComplete: true,
                        data,
                    });

                    navigate(ROUTES.downloadDocument.path);
                }}
            />
        </div>
    );
}

export default PreviewPage;
