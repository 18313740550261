import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import { AdminSingleSectionMetaSection } from '@INTERFACES/admin-api/sections';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { sectionsAdminApi } from './sections.admin-api';
import { chaptersAdminApi, extractSectionsTags } from './chapters.admin-api';
import invalidateData from '../helpers/invalidateData';
import { tagsAdminApi } from './tags.admin-api';
import { documentsAdminApi } from './documents.admin-api';
import { unpublishedAdminApi } from './unpublished.admin-api';
import { formatVersion } from '../helpers/formatVersion';
import { extractSectionsFilters } from '../helpers/extractSectionsFilters';

type VariantHistory = {
    data: VariantHistoryData[]
};

export type VariantHistoryData = {
    id: number;
    body: string;
    description: string;
    createdAt: string;
    author: {
        id: number;
        fullName: string;
        email: string;
    };
};

type VariantHistoryApiResponse = {
    id: number;
    body: string;
    description: string;
    created_at: string;
    author: {
        id: number;
        full_name: string;
        email: string;
    }
};

type CreateParams = {
    documentId: number;
    chapterId: number;
    sectionId: number;
    name: string;
    isDefault: boolean;
    isVisible: boolean;
    body: string;
    brands: {
        id: number,
    }[];
    regions: {
        id: number
    }[];
    areas: {
        id: number
    }[];
    countries: {
        id: number
    }[];
    propertyTypes: {
        id: number
    }[];
};

type CreateOrUpdateApiResponse = {
    id: number;
    name: string;
    is_default: boolean;
    is_visible: boolean;
    is_published: boolean;
    body: string;
    brands: {
        id: number;
        name: string;
    }[];
    regions: {
        id: number;
        name: string;
    }[];
    areas: {
        id: number;
        name: string;
    }[];
    countries: {
        id: number;
        name: string;
    }[];
    property_types: {
        id: number;
        name: string;
    }[];
};

type UpdateParams = {
    id: number;
    name?: string;
    documentId: number;
    chapterId: number;
    sectionId: number;
    isDefault: boolean;
    isVisible: boolean;
    body: string;
    brands: {
        id: number,
    }[];
    regions: {
        id: number,
    }[];
    areas: {
        id: number,
    }[];
    countries: {
        id: number,
    }[];
    propertyTypes: {
        id: number,
    }[];
};

type DeleteParams = {
    documentId: number;
    chapterId: number;
    sectionId: number;
    id: number;
};

type CreateAndAddTagParams = {
    documentId: number;
    chapterId: number;
    sectionId: number;
    id: number;
    tagName: string;
};

type AddTagApiResponse = {
    tag_id: number;
    tag_name: string;
};

type AddTagParams = {
    chapterId: number;
    sectionId: number;
    id: number;
    tagId: number;
};

type DeleteTagParams = {
    chapterId: number;
    sectionId: number;
    id: number;
    tagId: number;
};

type CopyApiResponse = {
    id: number;
    name: string;
    is_default: boolean;
    is_visible: boolean;
    is_published: boolean;
    brands: {
        id: number;
        name: string;
    }[];
    regions: {
        id: number;
        name: string;
    }[];
    areas: {
        id: number;
        name: string;
    }[];
    countries: {
        id: number;
        name: string;
    }[];
    property_types: {
        id: number;
        name: string;
    }[];
    body: string;
    tags: {
        id: number;
        name: string;
    }[];
    vendors: {
        id: number;
        name: string;
        company: string;
        address: string;
        email: string;
        phone: string;
        is_visible: boolean;
    }[];
    versions: {
        id: number;
        description: string;
        body: string;
        created_at: string;
        author: {
            id: number;
            full_name: string;
            email: string;
        };
    }[];
};

type CopyParams = {
    documentId: number;
    chapterId: number;
    sectionId: number;
    id: number;
};

type AddVendorApiResponse = {
    vendor_id: number;
    vendor_name: string;
    vendor_company: string;
    vendor_address: string;
    vendor_email: string;
    vendor_phone: string;
    vendor_is_visible: boolean;
};

type AddVendorParams = {
    sectionId: number;
    id: number;
    vendorId: number;
};

type DeleteVendorParams = {
    sectionId: number;
    id: number;
    vendorId: number;
};

const SortById = (a: any, b: any) => a.id - b.id;

function updateSingleSectionTags(
    {
        dispatch, sectionId, id, data,
    }: {
        dispatch: ThunkDispatch<any, unknown, AnyAction>;
        sectionId: number;
        id: number;
        data: {
            tagId: number;
            tagName: string;
        }
    },
) {
    dispatch(
        sectionsAdminApi.util.updateQueryData('getById', sectionId, (draft) => ({
            data: {
                ...draft.data,
                variants: draft.data.variants.map((variant) => {
                    if (variant.id === id) {
                        const tags = [...variant.tags, {
                            id: data.tagId,
                            name: data.tagName,
                        }];

                        tags.sort(SortById);

                        return {
                            ...variant,
                            tags,
                        };
                    }

                    return variant;
                }),
            },
            meta: draft.meta,
        })),
    );
}

function updateSingleChapterTags(
    {
        dispatch, sectionId, chapterId, id, data,
    }: {
        dispatch: ThunkDispatch<any, unknown, AnyAction>;
        sectionId: number;
        chapterId: number;
        id: number;
        data: {
            tagId: number;
            tagName: string;
        }
    },
) {
    dispatch(
        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
            data: {
                ...draft.data,
                sections: draft.data.sections.map((section) => {
                    if (section.id === sectionId) {
                        return {
                            ...section,
                            variants: section.variants.map((variant) => {
                                if (variant.id === id) {
                                    const tags = [...variant.tags, {
                                        id: data.tagId,
                                        name: data.tagName,
                                    }];

                                    tags.sort(SortById);

                                    return {
                                        ...variant,
                                        tags,
                                    };
                                }

                                return variant;
                            }),
                        };
                    }

                    return section;
                }),
            },
            meta: {
                ...draft.meta,
                tags: extractSectionsTags(draft.data.sections.reduce((acc: any, section: any) => {
                    const { variants } = section;

                    return [
                        ...acc,
                        ...section.tags,
                        ...variants.reduce((innerAcc: any, variant: any) => {
                            const { tags } = variant;

                            return [...innerAcc, ...tags, {
                                id: data.tagId,
                                name: data.tagName,
                            }];
                        }, []),
                    ];
                }, [])),
            },
        })),
    );
}

function transformHistoryResponse(raw: VariantHistoryApiResponse[]): VariantHistory {
    return {
        data: raw.map((version) => formatVersion(version)),
    };
}

export const variantsAdminApi = createApi({
    reducerPath: 'variants-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        history: builder.query<VariantHistory, number>({
            query: (id) => ({
                url: `/variants/${id}/history/`,
            }),
            transformResponse(raw: VariantHistoryApiResponse[]) {
                return transformHistoryResponse(raw);
            },
        }),
        create: builder.mutation<CreateOrUpdateApiResponse, CreateParams>({
            query: (params) => {
                const formData = new FormData();
                formData.append('section', params.sectionId.toString());
                formData.append('is_default', params.isDefault.toString());
                formData.append('name', params.name.toString());

                params.brands.forEach((brand) => {
                    formData.append('brand', brand.id.toString());
                });

                params.regions.forEach((region) => {
                    formData.append('region', region.id.toString());
                });

                params.areas.forEach((area) => {
                    formData.append('area', area.id.toString());
                });

                params.countries.forEach((country) => {
                    formData.append('country', country.id.toString());
                });

                params.propertyTypes.forEach((propertyType) => {
                    formData.append('property_type', propertyType.id.toString());
                });

                return {
                    url: '/variants/',
                    method: 'POST',
                    body: formData,
                };
            },
            async onQueryStarted(
                { documentId, chapterId, sectionId },
                { dispatch, queryFulfilled },
            ) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    // ADD VARIANT TO SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    if (chapter.id === chapterId) {
                                        return {
                                            ...chapter,
                                            sections: chapter.sections.map((section) => {
                                                if (section.id === sectionId) {
                                                    return {
                                                        ...section,
                                                        variants: [...section.variants, {
                                                            id: queryData.id,
                                                            isPublished: queryData.is_published,
                                                        }],
                                                    };
                                                }

                                                return section;
                                            }),
                                        };
                                    }

                                    return chapter;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // ADD VARIANT TO SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                            data: {
                                ...draft.data,
                                sections: draft.data.sections.map((section) => {
                                    if (section.id === sectionId) {
                                        return {
                                            ...section,
                                            variants: [...section.variants, {
                                                id: queryData.id,
                                                name: queryData.name,
                                                body: queryData.body || '',
                                                isDefault: queryData.is_default,
                                                isPublished: queryData.is_published,
                                                latestVersion: null,
                                                brands: queryData.brands,
                                                regions: queryData.regions,
                                                areas: queryData.areas,
                                                countries: queryData.countries,
                                                propertyTypes: queryData.property_types,
                                                tags: [],
                                            }],
                                        };
                                    }

                                    return section;
                                }),
                            },
                            meta: {
                                ...draft.meta,
                                filters: extractSectionsFilters(draft.data.sections.reduce((acc: any, section: any) => {
                                    const { variants } = section;

                                    return [...acc, ...variants.reduce((innerAcc: any, variant: any) => [...innerAcc, {
                                        brands: variant.brands,
                                        regions: variant.regions,
                                        areas: variant.areas,
                                        countries: variant.countries,
                                        propertyTypes: variant.propertyTypes,
                                    }], []), {
                                        brands: queryData.brands,
                                        regions: queryData.regions,
                                        areas: queryData.areas,
                                        countries: queryData.countries,
                                        propertyTypes: queryData.property_types,
                                    }];
                                }, [])),
                            },
                        })),
                    );

                    // ADD VARIANT TO SINGLE SECTION
                    const sectionPatch = dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                variants: [...draft.data.variants, {
                                    id: queryData.id,
                                    name: queryData.name,
                                    isDefault: queryData.is_default,
                                    isVisible: queryData.is_visible,
                                    isPublished: queryData.is_published,
                                    brands: queryData.brands,
                                    regions: queryData.regions,
                                    areas: queryData.areas,
                                    countries: queryData.countries,
                                    propertyTypes: queryData.property_types,
                                    body: '',
                                    tags: [],
                                    vendors: [],
                                    versions: [],
                                }],
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = sectionPatch.patches[0]?.value;

                    if (data) {
                        // ADD VARIANT TO EVERY SINGLE SECTION META
                        data.meta.sections.forEach((section: AdminSingleSectionMetaSection) => {
                            dispatch(
                                sectionsAdminApi.util.updateQueryData('getById', section.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        sections: draft.meta.sections.map((s) => {
                                            if (s.id === sectionId) {
                                                return {
                                                    ...s,
                                                    variants: [...s.variants, {
                                                        id: queryData.id,
                                                        name: queryData.name,
                                                    }],
                                                };
                                            }

                                            return s;
                                        }),
                                    },
                                })),
                            );
                        });
                    }

                    // ADD VARIANT TO SECTION VERSION HISTORY
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('history', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                variants: [...draft.data.variants, {
                                    id: queryData.id,
                                    name: queryData.name,
                                    isDefault: queryData.is_default,
                                    isPublished: queryData.is_published,
                                    brands: queryData.brands,
                                    regions: queryData.regions,
                                    areas: queryData.areas,
                                    countries: queryData.countries,
                                    propertyTypes: queryData.property_types,
                                    versions: [],
                                }],
                            },
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        update: builder.mutation<CreateOrUpdateApiResponse, UpdateParams>({
            query: (params) => {
                const formData = new FormData();

                formData.append('is_default', params.isDefault?.toString() || '');
                formData.append('body', params.body || '');
                formData.append('name', params.name || '');

                params.brands.forEach((brand) => {
                    formData.append('brand', brand.id.toString());
                });

                params.regions.forEach((region) => {
                    formData.append('region', region.id.toString());
                });

                params.areas.forEach((area) => {
                    formData.append('area', area.id.toString());
                });

                params.countries.forEach((country) => {
                    formData.append('country', country.id.toString());
                });

                params.propertyTypes.forEach((propertyType) => {
                    formData.append('property_type', propertyType.id.toString());
                });

                return {
                    url: `/variants/${params.id}/`,
                    method: 'PATCH',
                    body: formData,
                };
            },
            async onQueryStarted(
                {
                    id, documentId, chapterId, sectionId,
                },
                { dispatch, queryFulfilled },
            ) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    const updatedVariant = {
                        name: queryData.name,
                        isDefault: queryData.is_default,
                        isVisible: queryData.is_visible,
                        isPublished: queryData.is_published,
                        body: queryData.body || '',
                        brands: queryData.brands,
                        regions: queryData.regions,
                        areas: queryData.areas,
                        countries: queryData.countries,
                        propertyTypes: queryData.property_types,
                    };

                    // UPDATE VARIANT IN SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    if (chapter.id === chapterId) {
                                        return {
                                            ...chapter,
                                            sections: chapter.sections.map((section) => {
                                                if (section.id === sectionId) {
                                                    return {
                                                        ...section,
                                                        variants: section.variants.map((variant) => {
                                                            if (variant.id === id) {
                                                                return {
                                                                    ...variant,
                                                                    isPublished: queryData.is_published,
                                                                };
                                                            }

                                                            return variant;
                                                        }),
                                                    };
                                                }

                                                return section;
                                            }),
                                        };
                                    }

                                    return chapter;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // UPDATE VARIANT IN SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                            data: {
                                ...draft.data,
                                sections: draft.data.sections.map((section) => {
                                    if (section.id === sectionId) {
                                        return {
                                            ...section,
                                            variants: section.variants.map((variant) => {
                                                if (variant.id === queryData.id) {
                                                    return {
                                                        ...variant,
                                                        ...updatedVariant,
                                                    };
                                                }

                                                return variant;
                                            }),
                                        };
                                    }

                                    return section;
                                }),
                            },
                            meta: {
                                ...draft.meta,
                                filters: extractSectionsFilters(draft.data.sections.reduce((acc: any, section: any) => {
                                    const { variants } = section;

                                    return [...acc, ...variants.reduce((innerAcc: any, variant: any) => {
                                        if (variant.id === id) {
                                            return [...innerAcc, {
                                                brands: queryData.brands,
                                                regions: queryData.regions,
                                                areas: queryData.areas,
                                                countries: queryData.countries,
                                                propertyTypes: queryData.property_types,
                                            }];
                                        }

                                        return [...innerAcc, {
                                            brands: variant.brands,
                                            regions: variant.regions,
                                            areas: variant.areas,
                                            countries: variant.countries,
                                            propertyTypes: variant.propertyTypes,
                                        }];
                                    }, [])];
                                }, [])),
                            },
                        })),
                    );

                    // UPDATE VARIANT IN SINGLE SECTION
                    const sectionPatch = dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                variants: draft.data.variants.map((variant) => {
                                    if (variant.id === queryData.id) {
                                        return {
                                            ...variant,
                                            ...updatedVariant,
                                        };
                                    }

                                    return variant;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = sectionPatch.patches[0]?.value;

                    if (data) {
                        // UPDATE VARIANT IN EVERY SINGLE SECTION META
                        data.meta.sections.forEach((section: AdminSingleSectionMetaSection) => {
                            dispatch(
                                sectionsAdminApi.util.updateQueryData('getById', section.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        sections: draft.meta.sections.map((s) => {
                                            if (s.id === sectionId) {
                                                return {
                                                    ...s,
                                                    variants: s.variants.map((variant) => {
                                                        if (variant.id === id) {
                                                            return {
                                                                ...variant,
                                                                name: queryData.name,
                                                            };
                                                        }

                                                        return variant;
                                                    }),
                                                };
                                            }

                                            return s;
                                        }),
                                    },
                                })),
                            );
                        });
                    }

                    // UPDATE VARIANT IN SECTION VERSION HISTORY
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('history', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                variants: draft.data.variants.map((variant) => {
                                    if (variant.id === id) {
                                        return {
                                            ...variant,
                                            name: queryData.name,
                                            isDefault: queryData.is_default,
                                            isPublished: queryData.is_published,
                                            brands: queryData.brands,
                                            regions: queryData.regions,
                                            areas: queryData.areas,
                                            countries: queryData.countries,
                                            propertyTypes: queryData.property_types,
                                        };
                                    }

                                    return variant;
                                }),
                            },
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        delete: builder.mutation<void, DeleteParams>({
            query: (params) => ({
                url: `/variants/${params.id}/`,
                method: 'DELETE',
            }),
            async onQueryStarted(
                {
                    id, documentId, chapterId, sectionId,
                },
                { dispatch, queryFulfilled },
            ) {
                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    // DELETE VARIANT FROM SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    if (chapter.id === chapterId) {
                                        return {
                                            ...chapter,
                                            sections: chapter.sections.map((section) => {
                                                if (section.id === sectionId) {
                                                    return {
                                                        ...section,
                                                        variants: section.variants.filter((variant) => variant.id !== id),
                                                    };
                                                }

                                                return section;
                                            }),
                                        };
                                    }

                                    return chapter;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // DELETE VARIANT FROM SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                            data: {
                                ...draft.data,
                                sections: draft.data.sections.map((section) => {
                                    if (section.id === sectionId) {
                                        return {
                                            ...section,
                                            variants: section.variants.filter((variant) => variant.id !== id),
                                        };
                                    }

                                    return section;
                                }),
                            },
                            meta: {
                                ...draft.meta,
                                tags: extractSectionsTags(draft.data.sections.reduce((acc: any, section: any) => {
                                    const { variants } = section;

                                    return [
                                        ...acc,
                                        ...section.tags,
                                        ...variants
                                            .filter((variant: any) => variant.id !== id)
                                            .reduce((innerAcc: any, variant: any) => {
                                                const { tags } = variant;

                                                return [...innerAcc, ...tags];
                                            }, []),
                                    ];
                                }, [])),
                                filters: extractSectionsFilters(draft.data.sections.reduce((acc: any, section: any) => {
                                    const { variants } = section;

                                    return [...acc, ...variants
                                        .filter((variant: any) => variant.id !== id)
                                        .reduce((innerAcc: any, variant: any) => [...innerAcc, {
                                            brands: variant.brands,
                                            regions: variant.regions,
                                            areas: variant.areas,
                                            countries: variant.countries,
                                            propertyTypes: variant.propertyTypes,
                                        }], [])];
                                }, [])),
                            },
                        })),
                    );

                    // DELETE VARIANT FROM SINGLE SECTION
                    const sectionPatch = dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                variants: draft.data.variants.filter((variant) => variant.id !== id),
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = sectionPatch.patches[0]?.value;

                    if (data) {
                        // DELETE VARIANT FROM EVERY SINGLE SECTION META
                        data.meta.sections.forEach((section: AdminSingleSectionMetaSection) => {
                            dispatch(
                                sectionsAdminApi.util.updateQueryData('getById', section.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        sections: draft.meta.sections.map((s) => ({
                                            ...s,
                                            variants: s.variants.filter((variant) => variant.id !== id),
                                        })),
                                    },
                                })),
                            );
                        });
                    }

                    // DELETE VARIANT FROM SECTION VERSION HISTORY
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('history', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                variants: draft.data.variants.filter((variant) => variant.id !== id),
                            },
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        copy: builder.mutation<CopyApiResponse, CopyParams>({
            query: (params) => ({
                url: `/variants/${params.id}/copy/`,
                method: 'POST',
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const {
                    documentId,
                    chapterId,
                    sectionId,
                } = args;

                try {
                    const { data: queryData } = await queryFulfilled;
                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    // COPY VARIANT IN SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    if (chapter.id === chapterId) {
                                        return {
                                            ...chapter,
                                            sections: chapter.sections.map((section) => {
                                                if (section.id === sectionId) {
                                                    return {
                                                        ...section,
                                                        variants: [
                                                            ...section.variants,
                                                            {
                                                                id: queryData.id,
                                                                isPublished: queryData.is_published,
                                                            },
                                                        ],
                                                    };
                                                }

                                                return section;
                                            }),
                                        };
                                    }

                                    return chapter;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // COPY VARIANT IN SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                            data: {
                                ...draft.data,
                                sections: draft.data.sections.map((section) => {
                                    if (section.id === sectionId) {
                                        const variantLatestVersion = queryData.versions.length > 0
                                            ? formatVersion(queryData.versions[queryData.versions.length - 1])
                                            : null;

                                        return {
                                            ...section,
                                            variants: [
                                                ...section.variants,
                                                {
                                                    id: queryData.id,
                                                    name: queryData.name,
                                                    body: queryData.body || '',
                                                    isDefault: queryData.is_default,
                                                    isPublished: queryData.is_published,
                                                    latestVersion: variantLatestVersion,
                                                    brands: queryData.brands,
                                                    regions: queryData.regions,
                                                    areas: queryData.areas,
                                                    countries: queryData.countries,
                                                    propertyTypes: queryData.property_types,
                                                    tags: queryData.tags.map((tag) => ({
                                                        id: tag.id,
                                                        name: tag.name,
                                                    })).sort(SortById),
                                                },
                                            ],
                                        };
                                    }

                                    return section;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // COPY VARIANT IN SINGLE SECTION
                    const sectionPatch = dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                variants: [
                                    ...draft.data.variants,
                                    {
                                        id: queryData.id,
                                        name: queryData.name,
                                        isDefault: queryData.is_default,
                                        isVisible: queryData.is_visible,
                                        isPublished: queryData.is_published,
                                        brands: queryData.brands,
                                        regions: queryData.regions,
                                        areas: queryData.areas,
                                        countries: queryData.countries,
                                        propertyTypes: queryData.property_types,
                                        body: queryData.body || '',
                                        tags: queryData.tags.map((tag) => ({
                                            id: tag.id,
                                            name: tag.name,
                                        })).sort(SortById),
                                        vendors: queryData.vendors.map((vendor) => ({
                                            id: vendor.id,
                                            name: vendor.name,
                                            company: vendor.company,
                                            address: vendor.address,
                                            email: vendor.email,
                                            phone: vendor.phone,
                                            isVisible: vendor.is_visible,
                                        })),
                                        versions: queryData.versions.map((version) => formatVersion(version)),
                                    },
                                ],
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = sectionPatch.patches[0]?.value;

                    if (data) {
                        // COPY VARIANT IN EVERY SECTION META
                        data.meta.sections.forEach((section: AdminSingleSectionMetaSection) => {
                            dispatch(
                                sectionsAdminApi.util.updateQueryData('getById', section.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        sections: draft.meta.sections.map((s) => {
                                            if (s.id === sectionId) {
                                                return {
                                                    ...s,
                                                    variants: [
                                                        ...s.variants,
                                                        {
                                                            id: queryData.id,
                                                            name: queryData.name,
                                                        },
                                                    ],
                                                };
                                            }

                                            return s;
                                        }),
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
        createAndAddTag: builder.mutation<AddTagApiResponse, CreateAndAddTagParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    tag_name: params.tagName,
                });

                return {
                    url: `/variants/${params.id}/tags/`,
                    method: 'POST',
                    body: formData,
                };
            },
            async onQueryStarted(
                {
                    id, documentId, chapterId, sectionId,
                },
                { dispatch, queryFulfilled },
            ) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // ADD TAG TO SINGLE SECTION
                    updateSingleSectionTags({
                        dispatch,
                        sectionId,
                        id,
                        data: {
                            tagId: queryData.tag_id,
                            tagName: queryData.tag_name,
                        },
                    });

                    // ADD TAG TO SINGLE CHAPTER
                    updateSingleChapterTags({
                        dispatch,
                        sectionId,
                        chapterId,
                        id,
                        data: {
                            tagId: queryData.tag_id,
                            tagName: queryData.tag_name,
                        },
                    });

                    // ADD TAG TO DOCUMENT
                    dispatch(
                        tagsAdminApi.util.updateQueryData('getAll', { documentId }, (draft) => ({
                            data: [...draft.data, {
                                id: queryData.tag_id,
                                name: queryData.tag_name,
                            }],
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        addTag: builder.mutation<AddTagApiResponse, AddTagParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    tag_id: params.tagId,
                });

                return {
                    url: `/variants/${params.id}/tags/`,
                    method: 'PATCH',
                    body: formData,
                };
            },
            async onQueryStarted({ id, chapterId, sectionId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // ADD TAG TO SINGLE SECTION
                    updateSingleSectionTags({
                        dispatch,
                        sectionId,
                        id,
                        data: {
                            tagId: queryData.tag_id,
                            tagName: queryData.tag_name,
                        },
                    });

                    // ADD TAG TO SINGLE CHAPTER
                    updateSingleChapterTags({
                        dispatch,
                        sectionId,
                        chapterId,
                        id,
                        data: {
                            tagId: queryData.tag_id,
                            tagName: queryData.tag_name,
                        },
                    });
                } catch (e) {
                    //
                }
            },
        }),
        deleteTag: builder.mutation<void, DeleteTagParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    tag_id: params.tagId,
                });

                return {
                    url: `/variants/${params.id}/tags/`,
                    method: 'DELETE',
                    body: formData,
                };
            },
            async onQueryStarted(
                {
                    id,
                    chapterId,
                    sectionId,
                    tagId,
                },
                { dispatch, queryFulfilled },
            ) {
                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // DELETE TAG FROM SINGLE SECTION
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                variants: draft.data.variants.map((variant) => {
                                    if (variant.id === id) {
                                        return {
                                            ...variant,
                                            tags: variant.tags.filter((tag) => tag.id !== tagId),
                                        };
                                    }

                                    return variant;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // DELETE TAG FROM SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                            data: {
                                ...draft.data,
                                sections: draft.data.sections.map((section) => {
                                    if (section.id === sectionId) {
                                        return {
                                            ...section,
                                            variants: section.variants.map((variant) => {
                                                if (variant.id === id) {
                                                    return {
                                                        ...variant,
                                                        tags: variant.tags.filter((tag) => tag.id !== tagId),
                                                    };
                                                }

                                                return variant;
                                            }),
                                        };
                                    }

                                    return section;
                                }),
                            },
                            meta: {
                                ...draft.meta,
                                tags: extractSectionsTags(draft.data.sections.reduce((acc: any, section: any) => {
                                    const { variants } = section;

                                    return [
                                        ...acc,
                                        ...section.tags,
                                        ...variants.reduce((innerAcc: any, variant: any) => {
                                            const { tags } = variant;

                                            if (variant.id === id) {
                                                return [...innerAcc, ...tags.filter((tag: any) => tag.id !== tagId)];
                                            }

                                            return [...innerAcc, ...tags];
                                        }, []),
                                    ];
                                }, [])),
                            },
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        addVendor: builder.mutation<AddVendorApiResponse, AddVendorParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    vendor_id: params.vendorId,
                });

                return {
                    url: `/variants/${params.id}/vendors/`,
                    method: 'PATCH',
                    body: formData,
                };
            },
            async onQueryStarted({ id, sectionId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // ADD VENDOR TO SINGLE SECTION VARIANT
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                variants: draft.data.variants.map((variant) => {
                                    if (variant.id === id) {
                                        const vendors = [...variant.vendors, {
                                            id: queryData.vendor_id,
                                            name: queryData.vendor_name,
                                            company: queryData.vendor_company,
                                            address: queryData.vendor_address,
                                            email: queryData.vendor_email,
                                            phone: queryData.vendor_phone,
                                            isVisible: queryData.vendor_is_visible,
                                        }];

                                        vendors.sort(SortById);

                                        return {
                                            ...variant,
                                            vendors,
                                        };
                                    }

                                    return variant;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        deleteVendor: builder.mutation<void, DeleteVendorParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    vendor_id: params.vendorId,
                });

                return {
                    url: `/variants/${params.id}/vendors/`,
                    method: 'DELETE',
                    body: formData,
                };
            },
            async onQueryStarted({ id, sectionId, vendorId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // DELETE VENDOR FROM SINGLE SECTION VARIANT
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', sectionId, (draft) => ({
                            data: {
                                ...draft.data,
                                variants: draft.data.variants.map((variant) => {
                                    if (variant.id === id) {
                                        return {
                                            ...variant,
                                            vendors: variant.vendors.filter((vendor) => vendor.id !== vendorId),
                                        };
                                    }

                                    return variant;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
    }),
});

export const {
    useHistoryQuery: useVariantHistory,
    useCreateMutation: useCreateVariant,
    useUpdateMutation: useUpdateVariant,
    useDeleteMutation: useDeleteVariant,
    useCopyMutation: useCopyVariant,
    useCreateAndAddTagMutation: useCreateAndAddTagToVariant,
    useAddTagMutation: useAddTagToVariant,
    useDeleteTagMutation: useDeleteTagFromVariant,
    useAddVendorMutation: useAddVendorToVariant,
    useDeleteVendorMutation: useDeleteVendorFromVariant,
} = variantsAdminApi;
