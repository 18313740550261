import React, { useEffect } from 'react';

import classnames from 'classnames/bind';

import useDocumentNameModal from '@HOOKS/store/modals/useDocumentNameModal';
import usePreviewDocumentModal from '@HOOKS/store/modals/usePreviewDocumentModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import { AdminSingleDocument, useUpdateDocument } from '@SERVICES';

import ActionButton from '@COMPONENTS/COMMON/buttons/ActionButton/actionButton';
import { Avatar } from '@mui/material';
import HeaderImage from '@COMPONENTS/HEADERS/HeaderImage';
import { notify } from '@NOTIFICATION/Notificator';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import useAuth from '@HOOKS/useAuth';
import styles from './DocumentName.module.scss';
import DocumentTags from '../DocumentTags';
import DocumentPermission from '../DocumentPermission';

const cx: CX = classnames.bind(styles);

function DocumentName(props: Props) {
    const { documentData } = props;
    const { state: { user, permissions } } = useAuth();

    const { canEditDocument, canGenerateDocument } = useDocumentPermissions({
        documentOwnerId: documentData.data.owner.id,
        permissions: documentData.meta.permissions,
    });

    const [
        updateDocument,
        {
            isSuccess, isError, error,
        },
    ] = useUpdateDocument();

    const { actions } = useDocumentNameModal(() => true);

    const { actions: previewDocumentModalActions } = usePreviewDocumentModal(
        () => true,
    );

    const locked = !canEditDocument;

    const getAvatarName = (fullName: string) => (fullName.trim()
        ? fullName
            .trim()
            .split(/\s+/)
            .map((word) => word[0].toUpperCase())
            .join('')
        : '');

    const onUpload = (imageBase: any) => {
        updateDocument({
            id: documentData.data.id,
            name: documentData.data.name,
            cover: imageBase || null,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            notify.info('Document image has been updated', {
                toastId: 'update-document-image',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'update-document-image-permission-error',
                });
            } else {
                notify.error('Something wrong', {
                    toastId: 'update-document-image-error',
                });
            }
        }
    }, [isError, error]);

    return (
        <div className={cx('document-name', 'container')}>
            <div className={cx('document-header-wrapper')}>
                <HeaderImage
                    imageTitle="Document"
                    coverImage={documentData.data.cover}
                    onUpload={onUpload}
                />
                <div className={cx('document-main-wrap')}>
                    <div className={cx('name')}>
                        {`Document - ${documentData.data.name}`}
                        <div className={cx('action-button-wrapper')}>
                            {!locked && (
                                <ActionButton
                                    buttonText="Rename"
                                    tooltipTitle="Edit document name"
                                    variant="outlined"
                                    handleClick={() => {
                                        actions.open({
                                            documentData: documentData.data,
                                        });
                                    }}
                                    icon="edit"
                                />
                            )}
                            <ActionButton
                                icon="preview"
                                buttonText="Preview"
                                tooltipTitle="Document preview"
                                variant="outlined"
                                handleClick={() => {
                                    previewDocumentModalActions.open({
                                        documentData: documentData.data,
                                        canGenerateDocument,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className={cx('document-owner')}>
                        <div className={cx('owner')}>Document Owner</div>
                        <div className={cx('avatar-wrap')}>
                            <Avatar>
                                {getAvatarName(documentData.data.owner.fullName)}
                            </Avatar>
                            {documentData.data.owner.fullName
                                || documentData.data.owner.email}
                        </div>
                        {(user!.id === documentData.data.owner.id || permissions.isSuperuser) && <DocumentPermission documentData={documentData.data} />}
                    </div>
                    <div className={cx('body-wrapper')}>
                        <DocumentTags documentData={documentData} />
                    </div>
                </div>
            </div>
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocument;
};

export default DocumentName;
