import React, { useEffect, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import { useCreateVariant, useGetSectionByIdAdmin } from '@SERVICES';

import useNewVariantModal from '@HOOKS/store/modals/useNewVariantModal';

import BaseModal from '@MODALS/BaseModal';

import VariantForm, { VariantFormValues } from '@PAGES/admin/edit-section/components/VariantForm/VariantForm.component';

import { AdminSingleSection, AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';
import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';
import COLOR from '@CONSTANTS/COLOR.constant';
import styles from './NewVariantModal.module.scss';

const cx: CX = classnames.bind(styles);

const defaultData: AdminSingleSection = {
    data: null,
    meta: null,
};

function ModalBody({
    isLoading, //
    isDisabled,
    isError,
    canEditDocument,
    sectionMeta,
    documentId,
    chapterId,
    sectionId,
}: ModalBodyProps) {
    const [
        createVariant,
        {
            isLoading: isCreation, isSuccess: isCreationSuccess, isError: isCreationError, error: creationError,
        },
    ] = useCreateVariant();

    const [isDefaultVariant, setIsDefaultVariant] = useState(false);

    const {
        actions: newVariantModalActions,
    } = useNewVariantModal();

    useEffect(() => {
        if (isCreationSuccess) {
            notify.info('New variant has been created', {
                toastId: 'add-new-variant',
            });

            newVariantModalActions.close();
        }
    }, [isCreationSuccess, newVariantModalActions]);

    useEffect(() => {
        if (isCreationError) {
            if ((creationError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'add-new-variant-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'add-new-variant-error',
                });
            }
        }
    }, [isCreationError, creationError]);

    if (isLoading) {
        return (
            <div className={cx('loading')}>
                <CircularProgressIndicator
                    color={COLOR['marriott-primary']}
                    size={40}
                    thickness={4}
                />
            </div>
        );
    }

    if (isError) {
        return (
            <div className={cx('error')}>Something wrong!</div>
        );
    }

    return (
        <VariantForm
            locked={!canEditDocument}
            buttonTitle="Create"
            isLoading={isCreation}
            defaultValues={{
                isDisable: isDisabled,
                isDefault: isDefaultVariant,
                brands: [],
                regions: [],
                areas: [],
                countries: [],
                propertyTypes: [],
            }}
            handleToggleDefault={() => {
                setIsDefaultVariant((prev) => !prev);
            }}
            sectionMeta={sectionMeta}
            newVariant
            onSave={(formValues: VariantFormValues) => {
                const { isDefault } = formValues;
                console.log('__here', formValues);

                createVariant({
                    documentId,
                    chapterId,
                    sectionId,
                    name: formValues.name,
                    isVisible: false,
                    body: '',
                    isDefault,
                    brands: isDefault ? [] : formValues.brands,
                    regions: isDefault ? [] : formValues.regions,
                    areas: isDefault ? [] : formValues.areas,
                    countries: isDefault ? [] : formValues.countries,
                    propertyTypes: isDefault ? [] : formValues.propertyTypes,
                });
            }}
        />
    );
}

interface ModalBodyProps {
    isLoading: boolean;
    isDisabled: boolean;
    isError: boolean;
    canEditDocument: boolean;
    sectionMeta: AdminSingleSectionMeta;
    documentId: number;
    chapterId: number;
    sectionId: number;
}

function NewVariantModal() {
    const {
        state: { data: modalParams },
        actions: newVariantModalActions,
    } = useNewVariantModal();

    const { sectionId, canEditDocument } = modalParams!;

    const {
        data: sectionData = defaultData, isLoading: isSectionLoading, isError,
    } = useGetSectionByIdAdmin(sectionId);

    const variants = sectionData?.data?.variants || [];
    const hasDefault = variants?.some((variant) => variant.isDefault);

    function handleClose() {
        newVariantModalActions.close();
    }

    return (
        <BaseModal
            style={{ top: 140 }}
            onClose={() => handleClose()}
        >
            <div className={cx('new-variant-modal')}>
                <div className={cx('title')}>
                    Create new variant
                </div>

                <ModalBody
                    isLoading={isSectionLoading}
                    isError={isError}
                    canEditDocument={canEditDocument}
                    isDisabled={hasDefault}
                    sectionMeta={sectionData.meta!}
                    documentId={modalParams!.documentId}
                    chapterId={modalParams!.chapterId}
                    sectionId={sectionId}
                />
            </div>
        </BaseModal>
    );
}

export default NewVariantModal;
