import * as React from 'react';
import Button from '@mui/material/Button';

import classnames from 'classnames/bind';
import Tooltip from '@COMPONENTS/SHARED/Tooltip';
import { Badge } from '@mui/material';

import { ReactComponent as PreviewIcon } from '@ICONS/preview-icon.svg';
import { ReactComponent as SettingsIcon } from '@ICONS/settings-icon.svg';
import { ReactComponent as ClockIcon } from '@ICONS/clock-icon.svg';
import { ReactComponent as GridIcon } from '@ICONS/grid-icon.svg';
import { ReactComponent as ListIcon } from '@ICONS/list-icon.svg';
import { ReactComponent as EditIcon } from '@ICONS/edit-icon-2.svg';
import { ReactComponent as MandatoryIcon } from '@ICONS/mandatory-icon.svg';
import { ReactComponent as PageBreckIcon } from '@ICONS/page-breck-icon.svg';
import { ReactComponent as LandscapeIcon } from '@ICONS/landscape-icon.svg';

import styles from './actionButton.module.scss';

const cx: CX = classnames.bind(styles);

const icons: { [key: string]: any } = {
    preview: (props: any) => <PreviewIcon {...props} />,
    settings: (props: any) => <SettingsIcon {...props} />,
    clock: (props: any) => <ClockIcon {...props} />,
    grid: (props: any) => <GridIcon {...props} />,
    list: (props: any) => <ListIcon {...props} />,
    edit: (props: any) => <EditIcon {...props} />,
    mandatory: (props: any) => <MandatoryIcon {...props} />,
    pagebreck: (props: any) => <PageBreckIcon {...props} />,
    landscape: (props: any) => <LandscapeIcon {...props} />,
};

function ActionButton(props: ActionButtonProps) {
    const {
        buttonText, variant = 'text', handleClick, icon, tooltipTitle, isActive, locked, showbadge,
    } = props;

    const Icon = icon && icons[icon];

    const [tooltipId] = React.useState(Tooltip.getId());

    return (
        <div className={cx('action-button-wrapper', {
            'active-button': isActive,
        })}
        >
            <Badge color="secondary" variant="dot" invisible={!showbadge}>
                <Button id={tooltipId} variant={variant} onClick={handleClick} startIcon={Icon ? <Icon /> : undefined} disabled={locked}>{buttonText}</Button>
            </Badge>
            {tooltipTitle && (
                <Tooltip
                    id={tooltipId}
                    content={tooltipTitle}
                    disabled={false}
                />
            )}
        </div>
    );
}

interface ActionButtonProps {
    buttonText: string;
    variant?: 'text' | 'outlined' | 'contained';
    handleClick: () => void;
    icon?: 'preview' | 'settings' | 'clock' | 'grid' | 'list' | 'edit' | 'mandatory' | 'pagebreck' | 'landscape';
    tooltipTitle?: string;
    isActive?: boolean;
    locked?: boolean;
    showbadge?: boolean;
}

ActionButton.defaultProps = {
    variant: 'text',
    icon: '',
    tooltipTitle: undefined,
    isActive: false,
    locked: false,
    showbadge: false,
};

export default ActionButton;
