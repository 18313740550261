import { ContentChapter } from '@INTERFACES/api/content';
import Mandatory from '@PAGES/manager/select-content/helpers/mandatoryPredicate';

export default function getInitialVariantsIds(data: ContentChapter[], isPartial: boolean) {
    return data.reduce((acc: Set<number>, chapter: ContentChapter) => {
        const { sections } = chapter;

        if (!isPartial) {
            const mandatorySections = (
                chapter.isMandatory
                    ? sections
                    : sections.filter(Mandatory)
            );

            mandatorySections.forEach((section) => {
                const { variants } = section;

                if (variants.length > 0) {
                    acc.add(variants[0].id);
                }
            });
        }

        return acc;
    }, new Set());
}
