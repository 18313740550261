import React, {
    useState, useLayoutEffect, useMemo, useEffect,
    useCallback,
} from 'react';
import { Avatar, Button } from '@mui/material';
import classnames from 'classnames/bind';
import { ReactComponent as UserProfileIcon } from '@ICONS/user-profile-icon.svg';
import AlertDialog from '@COMPONENTS/COMMON/dialog/dialog';
import {
    AdminSingleDocumentData,
    AdminUser,
    useGetAllUsers,
    useUpdateDocumentPermissions,
} from '@SERVICES';
import { DOCUMENT_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';
import { notify } from '@NOTIFICATION/Notificator';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import styles from './DocumentPermission.module.scss';
import PermissionModal from '../PermissionModal';
import { permissions } from './types';

const cx: CX = classnames.bind(styles);

const defaultData = {
    data: [],
};

export type PermissionItem = {
    id: number;

    fullName: string;

    email: string;

    permissions: Set<DOCUMENT_PERMISSIONS>
};

function DocumentPermission(props: Props) {
    const { documentData } = props;

    const { data: usersData = defaultData } = useGetAllUsers();

    const [
        updatePermissions,
        {
            isSuccess: isUpdatePermissionsSuccess,
            isError: isUpdatePermissionsError,
            error: updatePermissionError,
        },
    ] = useUpdateDocumentPermissions();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedPermissions, setSelectedPermissions] = useState<
    PermissionItem[]
    >([]);

    useLayoutEffect(() => {
        if (!isOpen) return;
        setSelectedPermissions(
            documentData.users.map((user) => ({
                ...user,
                permissions: new Set(user.permissions.map((p) => p.codename)),
            })),
        );
    }, [documentData, isOpen]);

    const handleClose = useCallback(() => {
        if (!documentData.users.length) {
            setSelectedPermissions([]);
        }
        setIsOpen(false);
    }, [documentData.users]);

    useEffect(() => {
        if (isUpdatePermissionsSuccess) {
            handleClose();
            notify.info('Permissions has been updated', {
                toastId: 'update-user-document-permissions',
            });
        }
    }, [handleClose, isUpdatePermissionsSuccess]);

    useEffect(() => {
        if (isUpdatePermissionsError) {
            handleClose();
            if ((updatePermissionError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId:
                        'update-user-document-permissions-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'update-user-document-permissions-error',
                });
            }
        }
    }, [isUpdatePermissionsError, updatePermissionError, handleClose]);

    const copyPermissions = (data: any) => data.map((user: any) => ({
        ...user,
        permissions: new Set(user.permissions),
    }));

    const addUser = (user: AdminUser) => {
        const copy = copyPermissions(selectedPermissions);

        copy.push({
            ...user,
            permissions: new Set(permissions.map((p) => p.codename)),
        });

        copy.sort((a: PermissionItem, b: PermissionItem) => a.id - b.id);

        setSelectedPermissions(copy);
    };

    const handleCheckboxChange = (id: number, permission: string) => {
        setSelectedPermissions((prevPermissions) => prevPermissions.map((user) => {
            if (user.id === id) {
                const newPermissions = new Set(user.permissions);
                if (
                    newPermissions.has(permission as DOCUMENT_PERMISSIONS)
                ) {
                    newPermissions.delete(
                        permission as DOCUMENT_PERMISSIONS,
                    );
                } else {
                    newPermissions.add(permission as DOCUMENT_PERMISSIONS);
                }
                return { ...user, permissions: newPermissions };
            }
            return user;
        }));
    };

    const removeUser = (userId: number) => {
        const updatedPermissions = selectedPermissions.filter(
            (user) => user.id !== userId,
        );
        setSelectedPermissions(updatedPermissions);
    };

    const savePermissions = () => {
        updatePermissions({
            id: documentData.id,
            usersPermissions: selectedPermissions
                .map((user: PermissionItem) => ({
                    userId: user.id,
                    permissions: Array.from(user.permissions),
                }))
                .filter((user: any) => user.permissions.length > 0),
        });
    };

    const options = useMemo(
        () => usersData.data
            .filter(
                (user) => !selectedPermissions.find(
                    (u: PermissionItem) => u.id === user.id,
                ) && documentData.owner.id !== user.id,
            )
            .map((user) => {
                const name = user.fullName || user.email.split('@')[0];
                return {
                    ...user,
                    value: user.id,
                    label: name,
                    icon: (
                        <Avatar className={cx('user-avatar')}>
                            {name[0]}
                        </Avatar>
                    ),
                };
            }),
        [selectedPermissions, usersData.data, documentData],
    );

    return (
        <div className={cx('document-permission-wraper')}>
            <span>Document Permission</span>

            <Button
                onClick={() => setIsOpen(true)}
                startIcon={<UserProfileIcon />}
                variant="outlined"
            >
                Manage Permissions
            </Button>

            <AlertDialog
                open={isOpen}
                handleClose={handleClose}
                dialogContent={(
                    <PermissionModal
                        handleClose={handleClose}
                        options={options}
                        usersData={selectedPermissions}
                        addUser={addUser}
                        handleCheckboxChange={handleCheckboxChange}
                        removeUser={removeUser}
                        savePermissions={savePermissions}
                    />
                )}
                size="lg"
            />
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocumentData;
};

export default DocumentPermission;
