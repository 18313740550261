import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import {
    AdminSingleSectionData,
    AdminSingleSectionDataVariant,
    AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';

import { useCopyVariant, useUpdateVariant } from '@SERVICES';

import useDeleteVariantModal from '@HOOKS/store/modals/useDeleteVariantModal';
import usePreviewVariantModal from '@HOOKS/store/modals/usePreviewVariantModal';
import useVariantHistoryModal from '@HOOKS/store/modals/useVariantHistoryModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import OpenCloseButton from '@COMPONENTS/COMMON/buttons/OpenCloseButton';
import RoundIconButton from '@COMPONENTS/COMMON/buttons/RoundIconButton';

import NameInputModal, { FormValues } from '@MODALS/common/NameInputModal';
import styles from './VariantHeader.module.scss';

const cx: CX = classnames.bind(styles);

function VariantHeader(props: Props) {
    const {
        variant, sectionData, sectionMeta, isOpen, onOpenToggle,
    } = props;

    const { canEditDocument, canGenerateDocument } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const [updateVariant, {
        isLoading, isSuccess, isError, error,
    }] = useUpdateVariant();

    const [isOpenModal, setIsOpenModal] = useState(false);

    const [
        copyVariant,
        {
            isLoading: isCopyLoading,
            isSuccess: isCopySuccess,
            isError: isCopyError,
            error: copyError,
        },
    ] = useCopyVariant();

    const { actions: deleteVariantModalActions } = useDeleteVariantModal(
        () => true,
    );

    const { actions: previewVariantModalActions } = usePreviewVariantModal(
        () => true,
    );

    const { actions: variantHistoryModalActions } = useVariantHistoryModal(
        () => true,
    );

    useEffect(() => {
        if (isCopySuccess) {
            notify.info('Variant has been copied', {
                toastId: 'copy-variant',
            });
        }
    }, [isCopySuccess]);

    useEffect(() => {
        if (isCopyError) {
            if ((copyError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'copy-variant-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'copy-variant-error',
                });
            }
        }
    }, [isCopyError, copyError]);

    const handleSectionHeaderClick = (event: React.MouseEvent) => {
        if (event.target === event.currentTarget) {
            onOpenToggle(!isOpen);
        }
    };

    const onSave = (formValues: FormValues) => {
        const { isDefault } = variant;
        const updateparams = {
            documentId: sectionData.document.id,
            chapterId: sectionData.chapter.id,
            sectionId: sectionData.id,
            id: variant.id,
            name: formValues.name,
            isVisible: variant.isVisible,
            body: variant.body || '',
            isDefault,
            brands: isDefault ? [] : variant.brands,
            regions: isDefault ? [] : variant.regions,
            areas: isDefault ? [] : variant.areas,
            countries: isDefault ? [] : variant.countries,
            propertyTypes: isDefault ? [] : variant.propertyTypes,
        };

        updateVariant(updateparams);
    };

    return (
        <div
            className={cx('variant-header')}
            role="button"
            tabIndex={0}
            onClick={handleSectionHeaderClick}
        >
            <div className={cx('title-wrapper')}>
                <div className={cx('buttons')}>
                    <div className={cx('button-wrapper')}>
                        <RoundIconButton
                            tooltipTitle="Edit name"
                            locked={!canEditDocument}
                            icon="edit"
                            onClick={() => {
                                setIsOpenModal(true);
                            }}
                            isProcessing={isLoading}
                            disabled={isLoading}
                        />
                    </div>
                    <div className={cx('button-wrapper')}>
                        <RoundIconButton
                            tooltipTitle="Copy variant"
                            locked={!canEditDocument}
                            icon="copy"
                            isProcessing={isCopyLoading}
                            disabled={isCopyLoading}
                            onClick={() => {
                                copyVariant({
                                    documentId: sectionData.document.id,
                                    chapterId: sectionData.chapter.id,
                                    sectionId: sectionData.id,
                                    id: variant.id,
                                });
                            }}
                        />
                    </div>

                    {!variant.isDefault && (
                        <div className={cx('button-wrapper')}>
                            <RoundIconButton
                                tooltipTitle="Delete variant"
                                locked={!canEditDocument}
                                icon="delete"
                                onClick={() => {
                                    deleteVariantModalActions.open({
                                        id: variant.id,
                                        documentId: sectionData.document.id,
                                        chapterId: sectionData.chapter.id,
                                        sectionId: sectionData.id,
                                        variant,
                                    });
                                }}
                            />
                        </div>
                    )}

                    <div className={cx('button-wrapper')}>
                        <RoundIconButton
                            tooltipTitle="Variant preview"
                            icon="preview"
                            onClick={() => {
                                previewVariantModalActions.open({
                                    documentId: sectionData.document.id,
                                    variant,
                                    baseText: sectionData.baseText,
                                    isLandscape: sectionData.isLandscape,
                                    canGenerateDocument,
                                });
                            }}
                        />
                    </div>

                    <div className={cx('button-wrapper')}>
                        <RoundIconButton
                            tooltipTitle="Variant history"
                            icon="history"
                            onClick={() => {
                                variantHistoryModalActions.open({
                                    variant,
                                    isLandscape: sectionData.isLandscape,
                                });
                            }}
                        />
                    </div>
                </div>

                <div
                    className={cx('title')}
                    role="button"
                    tabIndex={0}
                    onClick={handleSectionHeaderClick}
                >
                    {variant.isDefault
                        ? 'Default variant'
                        : variant.name || `Variant ${variant.id}`}
                </div>
            </div>

            <div className={cx('open-close-button-wrapper')}>
                <OpenCloseButton
                    isOpen={isOpen}
                    onClick={() => {
                        onOpenToggle(!isOpen);
                    }}
                />
            </div>

            {isOpenModal && (
                <NameInputModal
                    modalTitle="Update variant name"
                    itemName="Variant"
                    inputPlaceholder="Variant name"
                    successMessage="Variant name has been updated!"
                    initialInputValue={variant.name}
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    isError={isError}
                    error={error}
                    onSave={onSave}
                    onClose={() => {
                        setIsOpenModal(false);
                    }}
                />
            )}
        </div>
    );
}

VariantHeader.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onOpenToggle: PropTypes.func.isRequired,
};

type Props = InferProps<typeof VariantHeader.propTypes> & {
    variant: AdminSingleSectionDataVariant;
    sectionData: AdminSingleSectionData;
    sectionMeta: AdminSingleSectionMeta;
};

export default VariantHeader;
