import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import {
    AdminSingleChapterData,
    AdminSingleChapterDataSection,
    AdminSingleChapterMeta,
} from '@INTERFACES/admin-api/chapters';

import SectionSubheader from '@PAGES/admin/edit-chapter/components/SectionSubheader';
import Warning from '@COMPONENTS/SHARED/Warning';

import Variant from '@PAGES/admin/edit-chapter/components/Variant';
import AddButton from '@COMPONENTS/COMMON/buttons/AddButton';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import useNewVariantModal from '@HOOKS/store/modals/useNewVariantModal';

import styles from './SectionBody.module.scss';

const cx: CX = classnames.bind(styles);

function SectionBody(props: Props) {
    const myRef = useRef<HTMLDivElement>(null);
    const { section, chapterData, chapterMeta } = props;

    const { canEditDocument } = useDocumentPermissions({
        documentOwnerId: chapterData.document.owner,
        permissions: chapterMeta.permissions,
    });

    const { actions: newVariantModalActions } = useNewVariantModal();

    const navigate = useNavigate();

    function getBaseTextWarning() {
        if (section.baseText.latestVersion === null) {
            return 'Introduction text is not published';
        }

        return 'Introduction text has unpublished changes';
    }

    function handleAddNewVariant() {
        newVariantModalActions.open({
            documentId: chapterData.document.id,
            chapterId: chapterData.id,
            sectionId: section.id,
            canEditDocument,
        });
    }

    useEffect(() => {
        if (myRef.current) {
            myRef.current.innerHTML = section.baseText.body;
        }
    }, [section.baseText.body]);

    return (
        <div className={cx('section-body')}>
            <SectionSubheader section={section} />

            {!section.baseText.isPublished && (
                <div className={cx('base-text-warning-wrapper')}>
                    <Warning text={getBaseTextWarning()} />
                </div>
            )}

            {section.tags.length > 0 && (
                <div className={cx('section-tags')}>
                    <div className={cx('section-tags-title')}>Section tags</div>
                    <div className={cx('section-tags-list')}>
                        {section.tags.map((tag) => {
                            const key = tag.id;

                            return (
                                <div
                                    key={key}
                                    className={cx('section-tag-item')}
                                >
                                    {tag.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {section.baseText.body.length > 0 && (
                <div className={cx('section-tags')}>
                    <div className={cx('section-tags-title')}>Introduction Text</div>
                    <div className={cx('section-bodyText')} ref={myRef} />
                </div>
            )}

            <div className={cx('variants')}>
                {section.variants.length ? (
                    <>
                        <div className={cx('variants-title')}>Variants</div>
                        <div className={cx('variants-list')}>
                            {section.variants.map((variant) => {
                                const key = variant.id;

                                return (
                                    <div
                                        key={key}
                                        tabIndex={0}
                                        role="button"
                                        className={cx('variant-item', {
                                            'alert-bar': !variant.isPublished,
                                        })}
                                        onClick={() => {
                                            navigate(
                                                ROUTES.admin.sections.id.get(
                                                    section.id,
                                                    variant.id,
                                                ),
                                            );
                                        }}
                                    >
                                        <Variant variant={variant} />
                                    </div>
                                );
                            })}

                            <div className={cx('add-variant-button-listed')}>
                                <AddButton
                                    locked={!canEditDocument}
                                    title="New variant"
                                    fontSize={36}
                                    onClick={() => handleAddNewVariant()}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={cx('no-variants')}>No variants</div>
                        <div className={cx('add-variant-button-bottom')}>
                            <AddButton
                                locked={!canEditDocument}
                                title="New variant"
                                fontSize={36}
                                onClick={() => handleAddNewVariant()}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

type Props = {
    section: AdminSingleChapterDataSection;
    chapterData: AdminSingleChapterData;
    chapterMeta: AdminSingleChapterMeta;
};

export default SectionBody;
