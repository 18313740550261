import React, { useState } from 'react';
import classNames from 'classnames/bind';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import AutoCompleteWithIcon from '@COMPONENTS/SHARED/AutoCompleteWithIcon';
import { OptionWithIcon } from '@COMPONENTS/SHARED/AutoCompleteWithIcon/AutoCompleteWithIcon.component';
import styles from './PermissionModal.module.scss';
import PermissionTable from './PermissionTable';

const cx: CX = classNames.bind(styles);

function PermissionModal(props: any) {
    const {
        handleClose,
        options,
        usersData,
        addUser,
        handleCheckboxChange,
        removeUser,
        savePermissions,
    } = props;

    const [value, setValue] = useState<OptionWithIcon<number> | null>(null);

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setValue(newValue);
    };

    const handleAddUser = () => {
        if (value === null) return;
        addUser(value);
        setValue(null);
    };

    const handleDeleteUser = (id: number) => {
        removeUser(id);
    };

    return (
        <div className={cx('permission-modal-wrapper')}>
            <div className={cx('permission-title')}>
                <span>Manage Permissions</span>
                <button
                    type="button"
                    className={cx('clear-button', 'close-button')}
                    onClick={handleClose}
                >
                    &times;
                </button>
            </div>
            <div className={cx('menu-wrapper')}>
                <AutoCompleteWithIcon
                    options={options}
                    value={value}
                    onChange={handleChange}
                />
                <Button
                    variant="outlined"
                    className={cx('active-button', {
                        active: value,
                    })}
                    onClick={handleAddUser}
                >
                    Add User
                </Button>
            </div>
            <div className={cx('tableMainWrapper')}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>User Info</TableCell>
                                <TableCell>Document Permissions</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <PermissionTable
                                users={usersData}
                                handlecheckboxChange={handleCheckboxChange}
                                handleDeleteUser={handleDeleteUser}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className={cx('footer-wrapper')}>
                <Button
                    variant="outlined"
                    className={cx('cancel-btn')}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    className={cx('save-btn')}
                    onClick={savePermissions}
                >
                    Save
                </Button>
            </div>
        </div>
    );
}

export default PermissionModal;
