import React, { useState } from 'react';

import classnames from 'classnames/bind';

import usePreviewChapterModal from '@HOOKS/store/modals/usePreviewChapterModal';

import BaseModal from '@MODALS/BaseModal';
import ModalHeader from '@MODALS/Previews/PreviewChapterModal/compontents/ModalHeader';
import ModalBody from '@MODALS/Previews/PreviewChapterModal/compontents/ModalBody';

import styles from './PreviewChapterModal.module.scss';

const cx: CX = classnames.bind(styles);

function PreviewChapterModal() {
    const {
        state: { data: modalParams },
        actions: previewChapterModalActions,
    } = usePreviewChapterModal(() => true);

    const { chapterData } = modalParams!;

    const [showUnpublished, setShowUnpublished] = useState<boolean>(true);

    const isPublished = (
        chapterData.introductionText.isPublished
        && chapterData.sections.every((section) => (
            section.baseText.isPublished
            && section.variants.every((v) => v.isPublished)
        ))
    );

    return (
        <BaseModal
            style={{
                top: 30,
                height: '100%',
                width: 'calc(100% - 60px)',
            }}
            onClose={() => {
                previewChapterModalActions.close();
            }}
        >
            <div className={cx('preview-chapter-modal')}>
                <div className={cx('header-wrapper')}>
                    <ModalHeader
                        isPublished={isPublished}
                        showUnpublished={showUnpublished}
                        setShowUnpublished={setShowUnpublished}
                    />
                </div>
                <div className={cx('content')}>
                    <ModalBody
                        chapterData={chapterData}
                        showUnpublished={showUnpublished}
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default PreviewChapterModal;
