import queryString from 'query-string';
import GenerationSteps from './GENERATION_STEPS.constant';

const ADMIN_PREFIX = '/manage';

type LogsParams = {
    page: number;

    actions?: number[];
};

const ROUTES = {
    login: { path: '/login' },

    main: { path: '/' },
    chooseDocument: { path: GenerationSteps.DOCUMENT },
    type: { path: GenerationSteps.TYPE },
    brand: { path: GenerationSteps.BRAND },
    location: { path: GenerationSteps.LOCATION },
    options: { path: GenerationSteps.OPTIONS },
    selectContent: { path: GenerationSteps.SELECT_CONTENT },
    preview: { path: GenerationSteps.PREVIEW },
    downloadDocument: { path: GenerationSteps.DOWNLOAD_DOCUMENT },

    admin: {
        path: ADMIN_PREFIX,
        manageVendors: { path: `${ADMIN_PREFIX}/vendors` },
        manageUsersPermissions: { path: `${ADMIN_PREFIX}/users-permissions` },
        logs: {
            path: `${ADMIN_PREFIX}/logs`,
            get(params: LogsParams) {
                const { page, actions = [] } = params;

                const query = queryString.stringify(
                    {
                        page: page === 1 ? undefined : page,
                        actions: actions.length ? actions : undefined,
                    },
                    {
                        arrayFormat: 'comma',
                    },
                );

                return `${ADMIN_PREFIX}/logs${query ? `?${query}` : ''}`;
            },
        },
        documents: {
            path: `${ADMIN_PREFIX}/documents`,
            id: {
                path: `${ADMIN_PREFIX}/documents/:id`,
                get(id: number) {
                    return `${ADMIN_PREFIX}/documents/${id}`;
                },
            },
        },
        chapters: {
            path: `${ADMIN_PREFIX}/chapters`,
            id: {
                path: `${ADMIN_PREFIX}/chapters/:id`,
                get(id: number) {
                    return `${ADMIN_PREFIX}/chapters/${id}`;
                },
            },
        },
        sections: {
            path: `${ADMIN_PREFIX}/sections`,
            id: {
                path: `${ADMIN_PREFIX}/sections/:id`,
                get(id: number, variant?: number) {
                    let hash = '';

                    if (variant) {
                        hash = `/#variant-${variant}`;
                    }

                    return `${ADMIN_PREFIX}/sections/${id}${hash}`;
                },
            },
        },
    },
};

export default Object.freeze(ROUTES);
