import React, {
    useCallback, useId, useMemo, useState,
} from 'react';
import Select, {
    components,
    CSSObjectWithLabel,
    MenuPlacement,
    MultiValue,
    OptionProps,
    Options,
} from 'react-select';

import classnames from 'classnames/bind';

import PropTypes, { InferProps } from 'prop-types';

import { ValueContainerMulti } from '@COMPONENTS/COMMON/inputs/select/base';

import { MenuListMulti } from '@COMPONENTS/COMMON/inputs/select/base/MenuListMulti';

import { selectBaseStyles } from '@COMPONENTS/COMMON/inputs/select/config';
import NoOptionsMessageBase from '@COMPONENTS/COMMON/inputs/select/base/NoOptionsMessageBase';
import ClearIndicatorBase from '@COMPONENTS/COMMON/inputs/select/base/ClearIndicatorBase';
import DropdownIndicatorBase from '@COMPONENTS/COMMON/inputs/select/base/DropdownIndicatorBase';
import FakeCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/FakeCheckbox';
import { AbstractSelectOption } from '@COMPONENTS/COMMON/inputs/select/types';

import styles from './BasicMultiSelect.module.scss';

const cx: CX = classnames.bind(styles);

function BasicMultiSelect<T extends AbstractSelectOption<unknown>>({
    title, //
    placeholder,
    options,
    value,
    isClearable,
    isDisabled,
    menuPlacement,
    onChange,
    onMenuOpen,
    onMenuClose,
}: Props<T>) {
    const inputId = useId();
    const [showplaceholder, setshowplaceholder] = useState<boolean>(true);
    const [inputValue, setInputValue] = useState<string>('');

    const sortedOptions = useMemo(
        () => [...options].sort((a, b) => a.label.localeCompare(b.label)),
        [options],
    );

    function handleChange(val: MultiValue<T>) {
        onChange(val);
    }

    const MemoizedOptionMulti = useCallback(
        ({ ...props }: OptionProps<T, true>) => {
            const { isSelected } = props;

            const { onMouseMove, onMouseOver, ...rest } = props.innerProps;

            const newProps = { ...props, innerProps: rest };

            return (
                <components.Option
                    className={cx('option', {
                        selected: isSelected,
                    })}
                    {...newProps}
                >
                    <FakeCheckbox
                        checked={props.isSelected}
                        label={props.label}
                    />
                </components.Option>
            );
        },
        [],
    );

    return (
        <div className={cx('basic-multi-select')}>
            <label htmlFor={inputId}>
                <div className={cx('title')}>{title}</div>
            </label>
            <Select<T, true>
                inputId={inputId}
                options={sortedOptions}
                isClearable={isClearable}
                isDisabled={isDisabled}
                placeholder={showplaceholder ? placeholder : ''}
                menuPlacement={menuPlacement as MenuPlacement}
                menuShouldScrollIntoView={false}
                value={value}
                onChange={(val: MultiValue<T>) => {
                    handleChange(val);
                    setInputValue('');
                }}
                onMenuOpen={() => {
                    setshowplaceholder(false);
                    onMenuOpen();
                }}
                onMenuClose={() => {
                    setshowplaceholder(true);
                    onMenuClose();
                }}
                isMulti
                unstyled
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                backspaceRemovesValue={false}
                inputValue={inputValue}
                onInputChange={(newValue) => {
                    setInputValue(newValue);
                }}
                components={{
                    IndicatorSeparator: () => null,
                    Placeholder: () => null,
                    MultiValue: () => null,
                    NoOptionsMessage: NoOptionsMessageBase,
                    ClearIndicator: ClearIndicatorBase,
                    DropdownIndicator: DropdownIndicatorBase,
                    ValueContainer: ValueContainerMulti,
                    ...(inputValue ? {} : { MenuList: MenuListMulti }),
                    Option: MemoizedOptionMulti,
                }}
                styles={{
                    ...selectBaseStyles,
                    option(
                        base: CSSObjectWithLabel,
                        props: OptionProps<T, true>,
                    ) {
                        return {
                            ...base,
                            backgroundColor: 'white',
                            padding: '12px 16px',
                            color: '#ADB4BD',
                            fontSize: 12,
                            cursor: props.isDisabled
                                ? 'not-allowed'
                                : 'pointer',
                        };
                    },
                }}
                classNames={{
                    valueContainer: () => cx('custom-value-container', {
                        'custom-placeholder-value-container':
                                !value.length && !showplaceholder,
                    }),
                }}
            />
        </div>
    );
}

BasicMultiSelect.defaultProps = {
    title: '',
    placeholder: 'Select...',
    isClearable: true,
    isDisabled: false,
    menuPlacement: 'auto',
    onMenuOpen: () => {},
    onMenuClose: () => {},
};

BasicMultiSelect.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onMenuOpen: PropTypes.func,
    onMenuClose: PropTypes.func,
    menuPlacement: PropTypes.oneOf(['auto', 'bottom', 'top']),
};

type Props<T extends AbstractSelectOption<unknown>> = InferProps<
    typeof BasicMultiSelect.propTypes
> &
    typeof BasicMultiSelect.defaultProps & {
    options: Options<T>;
    value: T[];
};

export default BasicMultiSelect;
