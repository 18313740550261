import React from 'react';

import classnames from 'classnames/bind';

import { AdminSingleSectionData, AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import useSectionNameModal from '@HOOKS/store/modals/useSectionNameModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import ActionButton from '@COMPONENTS/COMMON/buttons/ActionButton/actionButton';
import TagsComponent from '@COMPONENTS/COMMON/tags/tags.component';
import {
    useAddTagToSection, useCreateAndAddTagToSection, useDeleteTagFromSection, useGetAllTags,
} from '@SERVICES';
import styles from './SectionName.module.scss';

const cx: CX = classnames.bind(styles);

function SectionName(props: Props) {
    const {
        sectionData, sectionMeta, actionButton, bodyCotent,
    } = props;

    const {
        canEditDocument,
        canManageDocumentTags,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const {
        actions,
    } = useSectionNameModal(() => true);

    const [deleteTag, deleteTagResult] = useDeleteTagFromSection();
    const [addTagToSection, addTagToSectionResult] = useAddTagToSection();
    const [createAndAddTagToVariant, createAndAddTagToVariantResult] = useCreateAndAddTagToSection();

    return (
        <div className={cx('section-name', 'container')}>
            <div className={cx('content-wrapper')}>
                <div className={cx('name')}>
                    {`Section - ${sectionData?.name}`}
                </div>
                <div className={cx('action-button-wrapper')}>
                    {
                        canEditDocument
                        && (

                            <ActionButton
                                tooltipTitle="Edit section name"
                                buttonText="Rename"
                                variant="outlined"
                                handleClick={() => {
                                    actions.open({
                                        sectionData,
                                    });
                                }}
                                icon="edit"
                            />
                        )
                    }
                    {actionButton}
                </div>
            </div>
            <div className={cx('body-wrapper')}>
                <TagsComponent
                    locked={!canManageDocumentTags}
                    tags={sectionData.tags}
                    tagQuery={[deleteTag, deleteTagResult]}
                    apiParams={{
                        chapterId: sectionData.chapter.id,
                        id: sectionData.id,
                    }}
                    getAllTagsQuery={useGetAllTags}
                    getTagsParams={{
                        documentId: sectionData.document.id,
                    }}
                    addTagQuery={[addTagToSection, addTagToSectionResult]}
                    addTagParams={{
                        id: sectionData.id,
                        chapterId: sectionData.chapter.id,
                    }}
                    createAndAddTagQuery={[createAndAddTagToVariant, createAndAddTagToVariantResult]}
                    createAndAddTagParams={{
                        documentId: sectionData!.document!.id,
                        chapterId: sectionData!.chapter!.id,
                        id: sectionData!.id,
                    }}
                    sectionData={sectionData}
                />
                {bodyCotent && bodyCotent}
            </div>
        </div>
    );
}

type Props = {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
    actionButton?: React.ReactNode,
    bodyCotent?: React.ReactNode
};

SectionName.defaultProps = {
    actionButton: null,
    bodyCotent: null,
};

export default SectionName;
