import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleSectionDataVariantVendor } from '@INTERFACES/admin-api/sections';

import { ReactComponent as WarningIcon } from '@ICONS/warning-icon.svg';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';
import { ReactComponent as TrashIcon } from '@ICONS/trash-icon.svg';
import Lock from '@COMPONENTS/SHARED/Lock';

import styles from './VendorItem.module.scss';

const cx: CX = classnames.bind(styles);

function VendorItem(props: Props) {
    const {
        vendor,
        isProcessing,
        locked,
        showWarning,
        onClick,
    } = props;

    return (

        <div
            className={cx('vendor-item', 'customVendorClassNames', {
                'is-processing': isProcessing,
                locked,
            })}
        >
            {
                isProcessing
                && (
                    <div className={cx('progress-indicator-wrapper')}>
                        <CircularProgressIndicator
                            color={COLOR['marriott-light-grey']}
                            size={24}
                            thickness={3}
                        />
                    </div>
                )
            }

            <div className={cx('item-body')}>
                {
                    showWarning
                    && (
                        <div className={cx('warning')}>
                            <div className={cx('icon-wrapper')}>
                                <WarningIcon
                                    width={24}
                                    height={24}
                                />
                            </div>
                            <div className={cx('message')}>
                                Vendor already present at section level
                            </div>
                        </div>
                    )
                }
                <div className={cx('header-title')}>
                    <div className={cx('item-title')}>
                        {vendor.name}

                    </div>
                    {
                        locked
                            ? (
                                <div className={cx('lock-wrapper')}>
                                    <Lock size="small" />
                                </div>
                            ) : (

                                <button
                                    type="button"
                                    className={cx('clear-button', 'delete-button')}
                                    onClick={() => {
                                        onClick();
                                    }}
                                >
                                    <TrashIcon />
                                </button>
                            )
                    }
                </div>

                <div className={cx('body-title')}>
                    <span className={cx('name')}>Company</span>
                    <span className={cx('value')}>
                        {vendor.company}
                    </span>
                </div>
                <div className={cx('body-title')}>
                    <span className={cx('name')}>Address</span>
                    <span className={cx('value')}>{vendor.address}</span>
                </div>
                <div className={cx('body-title')}>
                    <span className={cx('name')}>Email</span>
                    <span className={cx('email')}>{vendor.email}</span>
                </div>
                <div className={cx('body-title')}>
                    <span className={cx('name')}>Phone</span>
                    <span className={cx('value')}>{vendor.phone}</span>
                </div>
            </div>
        </div>
    );
}

VendorItem.defaultProps = {
    showWarning: false,
};

VendorItem.propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    locked: PropTypes.bool.isRequired,
    showWarning: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof VendorItem.propTypes> & typeof VendorItem.defaultProps & {
    vendor: AdminSingleSectionDataVariantVendor
};

export default VendorItem;
