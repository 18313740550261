import React, {
    RefObject, useEffect, useRef, useState,
} from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { MultiValue } from 'react-select';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import {
    AdminSingleSectionData,
    AdminSingleSectionDataMandatoryCondition,
    AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';
import { useAddSectionMandatoryCondition, useUpdateSection } from '@SERVICES';

import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';
import Warning from '@COMPONENTS/SHARED/Warning';
import RoundedCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/RoundedCheckbox';

import BasicMultiSelect from '@COMPONENTS/COMMON/inputs/select/inputs/BasicMultiSelect';
import styles from './MandatorySidebar.module.scss';

const cx: CX = classnames.bind(styles);
const defaultOptions = {
    brands: [],
    regions: [],
    areas: [],
    countries: [],
    propertyTypes: [],
};

type Property = {
    id: number;
};

type Val = 'brands' | 'regions' | 'areas' | 'countries' | 'propertyTypes';

type PropertyOption = {
    label: string;
    value: number;
};

type ListItem = { id: number; name: string };
type Item = ListItem | null;

type OptionType = { id: number; name: string }[];

function getValue(sectionMeta: AdminSingleSectionMeta, properties: Property[], val: Val): PropertyOption[] {
    if (properties.length === 0) return [];

    const metaOptions = sectionMeta?.options || defaultOptions;

    const options = metaOptions[val];

    // TODO add ts-reset package
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return properties.map((p) => {
        const findOption = options.find((o) => o.id === p.id);

        if (!findOption) {
            return null;
        }

        const res = {
            value: findOption.id,
            label: findOption.name,
        };
        return res;
    }).filter(Boolean);
}

function MandatorySidebar(props: Props) {
    const {
        sectionData,
        sectionMeta,
        isAlwaysMandatory,
        setAlwaysMandatory,
        conditions,
        locked,
    } = props;

    const [
        updateSection,
        {
            isSuccess,
            isError,
            error,
        },
    ] = useUpdateSection();

    const [
        addCondition,
        {
            isLoading: isAddConditionLoading,
            isSuccess: isAddConditionSuccess,
            isError: isAddConditionError,
            error: eddConditionError,
        },
    ] = useAddSectionMandatoryCondition();

    const sidebarRef: RefObject<HTMLDivElement> = useRef(null);

    const [brandId, setBrandId] = useState<number | null>(null);
    const [regionId, setRegionId] = useState<number | null>(null);
    const [areaId, setAreaId] = useState<number | null>(null);
    const [countryId, setCountryId] = useState<number | null>(null);
    const [propertyTypeId, setPropertyTypeId] = useState<number | null>(null);

    const [showWarning, setShowWarning] = useState<boolean>(false);

    const metaOptions = sectionMeta.options || defaultOptions;

    type OptionsState = {
        brands: { id: number; name: string }[];
        regions: { id: number; name: string }[];
        areas: { id: number; name: string }[];
        countries: { id: number; name: string }[];
        propertyTypes: { id: number; name: string }[];
    };

    const [options, setOptions] = useState<OptionsState>({
        brands: [],
        regions: [],
        areas: [],
        countries: [],
        propertyTypes: [],
    });

    useEffect(() => {
        setShowWarning(false);
    }, [
        brandId,
        regionId,
        areaId,
        countryId,
        propertyTypeId,
    ]);

    useEffect(() => {
        if (isSuccess) {
            notify.info('Section mandatory options has been updated', {
                toastId: 'update-section-mandatory-option',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'update-section-mandatory-option-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'update-section-mandatory-option-error',
                });
            }
        }
    }, [isError, error]);

    useEffect(() => {
        if (isAddConditionSuccess) {
            notify.info('Condition has been added', {
                toastId: 'add-section-mandatory-condition',
            });

            setBrandId(null);
            setRegionId(null);
            setAreaId(null);
            setCountryId(null);
            setPropertyTypeId(null);
        }
    }, [isAddConditionSuccess]);

    useEffect(() => {
        if (isAddConditionError) {
            if ((eddConditionError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'add-section-mandatory-condition-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'add-section-mandatory-condition-error',
                });
            }
        }
    }, [isAddConditionError, eddConditionError]);

    function onMenuOpen() {
        if (sidebarRef.current) {
            sidebarRef.current.style.zIndex = '6';
        }
    }

    function onMenuClose() {
        if (sidebarRef.current) {
            sidebarRef.current.style.zIndex = '2';
        }
    }

    function checkAllExistence(conditionItem: Item[] | null, checkOptions: OptionType): boolean {
        if (!conditionItem || conditionItem.length === 0) return !checkOptions.length;

        return checkOptions.every((item) => conditionItem.some((option) => option?.id === item?.id));
    }

    function checkExistedCondition() {
        if (conditions.length === 0) return false;

        return conditions.some((condition) => {
            const brandExists = checkAllExistence(condition.brand, options.brands);
            const regionExists = checkAllExistence(condition.region, options.regions);
            const areaExists = checkAllExistence(condition.area, options.areas);
            const countryExists = checkAllExistence(condition.country, options.countries);
            const propertyTypeExists = checkAllExistence(condition.propertyType, options.propertyTypes);

            return brandExists && regionExists && areaExists && countryExists && propertyTypeExists;
        });
    }
    const disableConditionButton = (
        !options.brands.length
        && !options.regions.length
        && !options.areas.length
        && !options.countries.length
        && !options.propertyTypes.length
    ) || locked || sectionData.isMandatory;

    const handleUpdateSecion = (valIsAlwaysMandatory: boolean) => {
        updateSection({
            id: sectionData.id,
            chapterId: sectionData.chapter.id,
            documentId: sectionData.document.id,
            name: sectionData.name,
            body: sectionData.baseText.body,
            isPageBreak: sectionData.isPageBreak,
            isLandscape: sectionData.isLandscape,
            isMandatory: valIsAlwaysMandatory,
        });
    };

    return (
        <div
            ref={sidebarRef}
            className={cx('mandatory-sidebar')}
        >
            <div className={cx('always-mandatory-wrapper')}>
                <div className={cx('checkbox-wrapper')}>
                    <div className={cx('checkbox-title')}>
                        Always mandatory
                    </div>
                    <RoundedCheckbox
                        color={COLOR['app-green']}
                        checked={isAlwaysMandatory}
                        onChange={() => {
                            setAlwaysMandatory((prevValue: boolean) => {
                                const newValue = !prevValue;
                                handleUpdateSecion(newValue);
                                return newValue;
                            });
                        }}
                    />
                </div>
            </div>

            <div
                className={cx('conditions', {
                    hide: sectionData.isMandatory,
                })}
            >
                <div className={cx('conditions-title')}>
                    Conditions
                </div>

                <div
                    className={cx('selects')}
                >
                    <div className={cx('select-wrapper')}>
                        <BasicMultiSelect
                            title="Brands"
                            placeholder="Choose Brands"
                            options={metaOptions.brands.map((data) => ({
                                ...data,
                                value: data.id,
                                label: data.name,
                            }))}
                            value={getValue(sectionMeta, options.brands, 'brands')}
                            onChange={(val: MultiValue<PropertyOption>) => {
                                setOptions((opts) => ({
                                    ...opts,
                                    brands: val.map((p) => ({ id: p.value, name: p.label })),
                                }));
                            }}
                            onMenuOpen={() => onMenuOpen()}
                            onMenuClose={() => onMenuClose()}
                        />

                        <div className={cx('plus')}>+</div>
                    </div>
                    <div className={cx('select-wrapper')}>
                        <BasicMultiSelect
                            title="Regions"
                            placeholder="Choose Regions"
                            options={metaOptions.regions.map((data) => ({
                                ...data,
                                value: data.id,
                                label: data.name,
                            }))}
                            value={getValue(sectionMeta, options.regions, 'regions')}
                            onChange={(val: MultiValue<PropertyOption>) => {
                                setOptions((opts) => ({
                                    ...opts,
                                    regions: val.map((p) => ({ id: p.value, name: p.label })),
                                }));
                            }}
                            onMenuOpen={() => onMenuOpen()}
                            onMenuClose={() => onMenuClose()}
                        />
                        <div className={cx('plus')}>+</div>
                    </div>
                    <div className={cx('select-wrapper')}>
                        <BasicMultiSelect
                            title="Areas"
                            placeholder="Choose Areas"
                            options={metaOptions.areas.map((data) => ({
                                ...data,
                                value: data.id,
                                label: data.name,
                            }))}
                            value={getValue(sectionMeta, options.areas, 'areas')}
                            onChange={(val: MultiValue<PropertyOption>) => {
                                setOptions((opts) => ({
                                    ...opts,
                                    areas: val.map((p) => ({ id: p.value, name: p.label })),
                                }));
                            }}
                            onMenuOpen={() => onMenuOpen()}
                            onMenuClose={() => onMenuClose()}
                        />
                        <div className={cx('plus')}>+</div>
                    </div>
                    <div className={cx('select-wrapper')}>
                        <BasicMultiSelect
                            title="Countries"
                            placeholder="Choose Countries"
                            options={metaOptions.countries.map((data) => ({
                                ...data,
                                value: data.id,
                                label: data.name,
                            }))}
                            value={getValue(sectionMeta, options.countries, 'countries')}
                            onChange={(val: MultiValue<PropertyOption>) => {
                                setOptions((opts) => ({
                                    ...opts,
                                    countries: val.map((p) => ({ id: p.value, name: p.label })),
                                }));
                            }}
                            onMenuOpen={() => onMenuOpen()}
                            onMenuClose={() => onMenuClose()}
                        />
                        <div className={cx('plus')}>+</div>
                    </div>
                    <div className={cx('select-wrapper')}>
                        <BasicMultiSelect
                            title="Property Type"
                            placeholder="Choose Property Type"
                            options={metaOptions.propertyTypes.map((data) => ({
                                ...data,
                                value: data.id,
                                label: data.name,
                            }))}
                            value={getValue(sectionMeta, options.propertyTypes, 'propertyTypes')}
                            onChange={(val: MultiValue<PropertyOption>) => {
                                setOptions((opts) => ({
                                    ...opts,
                                    propertyTypes: val.map((p) => ({ id: p.value, name: p.label })),
                                }));
                            }}
                            onMenuOpen={() => onMenuOpen()}
                            onMenuClose={() => onMenuClose()}
                        />
                    </div>
                </div>

                <div
                    className={cx('warning-wrapper', {
                        show: showWarning,
                    })}
                >
                    <Warning text="Current condition already exists" />
                </div>

                <BasicButton
                    locked={locked}
                    title="Add condition"
                    isProcessing={isAddConditionLoading}
                    style={{
                        pointerEvents: disableConditionButton ? 'none' : 'all',
                        height: 40,
                        width: '100%',
                        fontSize: 14,
                        backgroundColor: disableConditionButton ? COLOR['marriott-light-grey'] : COLOR['app-green'],
                    }}
                    onClick={() => {
                        if (!disableConditionButton && !sectionData.isMandatory) {
                            const alreadyExists = checkExistedCondition();

                            if (alreadyExists) {
                                setShowWarning(true);
                            } else {
                                addCondition({
                                    brandId: options.brands.map(({ id }) => id),
                                    regionId: options.regions.map(({ id }) => id),
                                    areaId: options.areas.map(({ id }) => id),
                                    countryId: options.countries.map(({ id }) => id),
                                    propertyTypeId: options.propertyTypes.map(({ id }) => id),
                                    sectionId: sectionData.id,
                                });
                                setOptions(defaultOptions);
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
}

MandatorySidebar.propTypes = {
    isAlwaysMandatory: PropTypes.bool.isRequired,
    setAlwaysMandatory: PropTypes.func.isRequired,
    locked: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof MandatorySidebar.propTypes> & {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
    conditions: AdminSingleSectionDataMandatoryCondition[],
};

export default MandatorySidebar;
