import React, { useEffect, useMemo, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminSingleSectionData, AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import usePublishSectionModal from '@HOOKS/store/modals/usePublishSectionModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import PublishAllButton from '@COMPONENTS/COMMON/buttons/PublishAllButton';

import styles from './FloatingPublishButton.module.scss';

const cx: CX = classnames.bind(styles);

function FloatingPublishButton(props: Props) {
    const {
        sectionData,
        sectionMeta,
        top,
        isBaseTextOpen,
    } = props;

    const {
        canPublishDocument,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const {
        actions: publishSectionModalActions,
    } = usePublishSectionModal(() => true);

    const [toRight, setToRight] = useState<boolean>(false);

    const isPublished = useMemo(
        () => (
            sectionData.variants.every((variant) => variant.isPublished)
            && sectionData.baseText.isPublished
        ),
        [sectionData],
    );

    useEffect(() => {
        const element = document.getElementById('variants-sticky-header');

        function onScroll() {
            if (element) {
                const box = element.getBoundingClientRect();

                const passed = box.top <= top + 2;

                if (passed) {
                    setToRight(true);
                } else {
                    setToRight(false);
                }
            }
        }

        onScroll();

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [top, isBaseTextOpen]);

    return (
        <div
            className={cx('floating-publish-button', {
                'to-right': toRight,
            })}
            style={{
                top,
            }}
        >
            {!isPublished && (
                <div className={cx('publish-wrapper')}>
                    <PublishAllButton
                        disabled={isPublished}
                        canPublishDocument={canPublishDocument}
                        onClick={() => {
                            if (!isPublished) {
                                publishSectionModalActions.open({
                                    sectionData,
                                });
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
}

FloatingPublishButton.propTypes = {
    top: PropTypes.number.isRequired,
    isBaseTextOpen: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof FloatingPublishButton.propTypes> & {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default FloatingPublishButton;
