import React from 'react';

import classnames from 'classnames/bind';

import DESIGN from '@CONSTANTS/DESIGN.constant';

import { AdminSingleDocument } from '@SERVICES';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import useNewChapterModal from '@HOOKS/store/modals/useNewChapterModal';

import DocumentName from '@PAGES/admin/document/components/DocumentName';
import Chapter from '@PAGES/admin/document/components/Chapter';
import ChaptersStickyHeader from '@PAGES/admin/document/components/ChaptersStickyHeader';

import AddButton from '@COMPONENTS/COMMON/buttons/AddButton';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'] + DESIGN['admin-breadcrumbs-height'];

function Content(props: Props) {
    const { documentData } = props;

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId: documentData.data.owner.id,
        permissions: documentData.meta.permissions,
    });

    const {
        actions: newChapterModalActions,
    } = useNewChapterModal(() => true);

    return (
        <div className={cx('content')}>
            <DocumentName
                documentData={documentData}
            />

            <ChaptersStickyHeader
                top={top}
                documentData={documentData}
            />

            <div className="container max-constraint center">
                <div className={cx('grid')}>
                    <div className={cx('grid-item')}>
                        <div className={cx('add-chapter-button')}>
                            <AddButton
                                locked={!canEditDocument}
                                title="New chapter"
                                fontSize={36}
                                onClick={() => {
                                    newChapterModalActions.open({
                                        documentId: documentData.data.id,
                                    });
                                }}
                            />
                        </div>
                    </div>

                    {
                        documentData!.data.chapters.map((chapter, i) => {
                            const chapterKey = chapter.id;

                            return (
                                <div
                                    key={chapterKey}
                                    className={cx('grid-item')}
                                >
                                    <Chapter
                                        documentId={documentData.data.id}
                                        documentOwnerId={documentData.data.owner.id}
                                        permissions={documentData.meta.permissions}
                                        chapter={chapter}
                                        index={i}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocument,
};

export default Content;
