import React, { useEffect, useMemo, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SingleValue } from 'react-select';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import { useAddVendorToVariant, useGetAllVendors } from '@SERVICES';
import {
    AdminSingleSectionData,
    AdminSingleSectionDataVariant,
    AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import ApplySelectButton from '@COMPONENTS/COMMON/buttons/ApplySelectButton';

import BasicSelect from '@COMPONENTS/COMMON/inputs/select/inputs/BasicSelect';
import styles from './VariantVendorsControls.module.scss';

const cx: CX = classnames.bind(styles);

function VariantVendorsControls(props: Props) {
    const {
        variant, sectionData, sectionMeta,
    } = props;

    const { canManageDocumentVendors } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const { data: vendorsData = { data: [] }, isLoading: isVendorsLoading } = useGetAllVendors();

    const [addVendor, {
        isLoading, isSuccess, isError, error,
    }] = useAddVendorToVariant();

    const [selectedVendorId, setSelectedVendorId] = useState<number | null>(
        null,
    );

    useEffect(() => {
        if (isSuccess) {
            notify.info('New vendor has been added', {
                toastId: 'add-vendor-to-variant',
            });

            setSelectedVendorId(null);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'add-vendor-to-variant-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'add-vendor-to-variant-error',
                });
            }
        }
    }, [isError, error]);

    const options = useMemo(
        () => vendorsData.data
            .filter((vendor) => vendor.isVisible)
            .filter(
                (vendor) => !variant.vendors.find((t) => t.id === vendor.id),
            )
            .map((vendor) => ({
                ...vendor,
                value: vendor.id,
                label: vendor.name,
            })),
        [variant, vendorsData.data],
    );

    const value = useMemo(
        () => options.find((opt) => opt.id === selectedVendorId) || null,
        [options, selectedVendorId],
    );

    return (
        <div className={cx('variant-vendors-controls')}>
            <div className={cx('select-wrapper')}>
                <BasicSelect
                    title=""
                    placeholder="Select vendor"
                    isSearchable
                    options={options}
                    value={value}
                    menuPlacement="top"
                    isLoading={isVendorsLoading}
                    onChange={(val: SingleValue<any>) => {
                        setSelectedVendorId(val?.id || null);
                    }}
                />
            </div>
            <div className={cx('button-wrapper')}>
                <ApplySelectButton
                    title="Add Vendor"
                    locked={!canManageDocumentVendors}
                    isProcessing={isLoading}
                    disabled={selectedVendorId === null}
                    onClick={() => {
                        if (selectedVendorId !== null) {
                            addVendor({
                                sectionId: sectionData.id,
                                id: variant.id,
                                vendorId: selectedVendorId,
                            });
                        }
                    }}
                />
            </div>
        </div>
    );
}

type Props = {
    variant: AdminSingleSectionDataVariant;
    sectionData: AdminSingleSectionData;
    sectionMeta: AdminSingleSectionMeta;
};

export default VariantVendorsControls;
