import React, { useState } from 'react';

import classNames from 'classnames/bind';

import usePreviewSectionBaseTextModal from '@HOOKS/store/modals/usePreviewSectionBaseTextModal';
import useDownloadDocumentPreviewWrapper from '@HOOKS/useDownloadDocumentPreviewWrapper';

import BaseModal from '@MODALS/BaseModal';
import ViewMode from '@MODALS/Previews/components/ViewMode';
import DocumentPreviewPage from '@COMPONENTS/SHARED/DocumentPreviewPage';
import GeneratePreviewPDFButton from '@COMPONENTS/COMMON/buttons/GeneratePreviewPDFButton';

import styles from './PreviewSectionBaseTextModal.module.scss';

const cx: CX = classNames.bind(styles);

function PreviewSectionBaseTextModal() {
    const {
        state: { data: modalParams },
        actions: previewSectionBaseTextModalActions,
    } = usePreviewSectionBaseTextModal(() => true);

    const [
        downloadDocumentPreview,
        { isLoading },
    ] = useDownloadDocumentPreviewWrapper();

    const { sectionData, canGenerateDocument } = modalParams!;
    const { baseText, isLandscape } = sectionData;

    const { isPublished } = baseText;

    const [showUnpublished, setShowUnpublished] = useState<boolean>(false);

    const baseTextBody = showUnpublished
        ? (baseText.body || '')
        : (baseText.versions[baseText.versions.length - 1]?.body || '');

    function handleClose() {
        previewSectionBaseTextModalActions.close();
    }

    return (
        <BaseModal
            style={{
                top: 30,
                height: '100%',
            }}
            onClose={() => handleClose()}
        >
            <div className={cx('preview-section-base-text-modal')}>
                <div className={cx('header')}>
                    <div className={cx('title-wrapper')}>
                        <div className={cx('title')}>
                            Introduction text preview
                        </div>
                        <ViewMode
                            disabled={isPublished}
                            showUnpublished={showUnpublished}
                            setShowUnpublished={setShowUnpublished}
                        />
                    </div>
                    <div className={cx('pdf-button-wrapper')}>
                        <GeneratePreviewPDFButton
                            isProcessing={isLoading}
                            canGenerateDocument={canGenerateDocument}
                            onClick={() => {
                                downloadDocumentPreview({
                                    document: sectionData.document.id,
                                    variants: [],
                                    sections: [sectionData.id],
                                    chapters: [],
                                    isUnpublished: showUnpublished,
                                });
                            }}
                        />
                    </div>
                    <div className={cx('close-button-wrapper')}>
                        <button
                            type="button"
                            className={cx('clear-button', 'close-button')}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            &times;
                        </button>
                    </div>
                </div>
                <div className={cx('content')}>
                    <div className={cx('page-wrapper')}>
                        <DocumentPreviewPage
                            key={showUnpublished.toString()}
                            body={baseTextBody}
                            isLandscape={isLandscape}
                        />
                    </div>
                </div>
            </div>
        </BaseModal>
    );
}

export default PreviewSectionBaseTextModal;
