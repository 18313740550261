import React, { useEffect, useState, useCallback } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classnames from 'classnames/bind';
import { Typography } from '@mui/material';

import { notify } from '@NOTIFICATION/Notificator';
import { useUpdateSection } from '@SERVICES';
import { AdminSingleSectionData, AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';
import SectionName from '@PAGES/admin/edit-section/components/SectionName';
import ActionButton from '@COMPONENTS/COMMON/buttons/ActionButton/actionButton';

import styles from './PageHeader.module.scss';
import { SaveOption } from './types';

const cx: CX = classnames.bind(styles);

function PageHeader({
    sectionData, sectionMeta, actionButton, bodyCotent,
}: Props) {
    const [updateSection, { isSuccess, isError, error }] = useUpdateSection();
    const [isPageBreak, setPageBreak] = useState<boolean>(sectionData.isPageBreak);
    const [isLandscape, setLandscape] = useState<boolean>(sectionData.isLandscape);

    useEffect(() => {
        if (isSuccess) {
            notify.info('Section has been updated', { toastId: 'update-section-params' });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            const errorMessage = (error as FetchBaseQueryError).status === 403
                ? 'Permission denied'
                : 'Something went wrong!';
            notify.error(errorMessage, { toastId: 'update-section-params-error' });
        }
    }, [isError, error]);

    const handleSave = useCallback((option: SaveOption) => {
        updateSection({
            id: sectionData.id,
            chapterId: sectionData.chapter.id,
            documentId: sectionData.document.id,
            name: sectionData.name,
            body: sectionData.baseText.body,
            isMandatory: sectionData.isMandatory,
            isPageBreak,
            isLandscape,
            ...option,
        });
    }, [sectionData, isPageBreak, isLandscape, updateSection]);

    const toggleOption = useCallback((optionName: 'isPageBreak' | 'isLandscape') => {
        const setter = optionName === 'isPageBreak' ? setPageBreak : setLandscape;
        setter((prevValue) => {
            const newValue = !prevValue;
            handleSave({ [optionName]: newValue });
            return newValue;
        });
    }, [handleSave]);

    return (
        <div className={cx('page-header')}>
            <div className={cx('page-wrap')}>
                <SectionName
                    sectionData={sectionData}
                    sectionMeta={sectionMeta}
                    actionButton={actionButton}
                    bodyCotent={bodyCotent}
                />
                <div className={cx('footer-wrapper')}>
                    <Typography>Options:</Typography>
                    <div className={cx('footer-body')}>
                        <ActionButton
                            buttonText="Page Break before Section starts"
                            variant="outlined"
                            handleClick={() => toggleOption('isPageBreak')}
                            isActive={isPageBreak}
                            icon="pagebreck"
                        />
                        <ActionButton
                            buttonText="Landscape Mode"
                            variant="outlined"
                            handleClick={() => toggleOption('isLandscape')}
                            isActive={isLandscape}
                            icon="landscape"
                        />
                    </div>
                </div>
                <div className={cx('section-options', 'container')} />
            </div>
        </div>
    );
}

interface Props {
    sectionData: AdminSingleSectionData;
    sectionMeta: AdminSingleSectionMeta;
    actionButton?: React.ReactNode;
    bodyCotent?: React.ReactNode;
}

PageHeader.defaultProps = {
    actionButton: null,
    bodyCotent: null,
};

export default PageHeader;
