import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';
import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import { BrandStepData } from '@REDUCERS';

import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';

import StepSidebar from '@COMPONENTS/SIDEBARS/StepSidebar';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import ContentWithStickySidebar from '@COMPONENTS/SHARED/ContentWithStickySidebar';

import ReactMap from '@COMPONENTS/COMMON/map/reactMap';
import { useGetAllCountriesQuery } from '@SERVICES';
import SearchbarInput from '@COMPONENTS/COMMON/inputs/input/SearchInput';
import styles from './Location.module.scss';

const cx: CX = classnames.bind(styles);

function LocationPage() {
    const {
        actions: documentGenerationStepsActions,
        services: documentGenerationStepsServices,
    } = useDocumentGenerationSteps(() => true);

    const { data: locationData } = documentGenerationStepsServices.getStepData(
        GenerationSteps.LOCATION,
    );
    const [locationName, setLocationName] = useState<string>('');

    useEffect(() => {
        if (!locationData) return;
        if ('name' in locationData && locationData.name) {
            setLocationName(locationData.name);
        }
    }, [locationData]);

    const { data: countriesData = [] } = useGetAllCountriesQuery();

    const navigate = useNavigate();

    const options = useMemo(
        () => countriesData.map((country: { id: number; name: string }) => ({
            value: country.id,
            label: country.name,
        })),
        [countriesData],
    );

    const handleClick = useCallback(
        (name: string) => {
            const countryId = options.find((country) => country.label === name)?.value;
            if (!countryId) return;
            const data: BrandStepData = {
                id: countryId,
                name,
            };

            documentGenerationStepsActions.apply({
                id: GenerationSteps.LOCATION,
                isComplete: true,
                data,
            });

            navigate(ROUTES.options.path);
        },
        [documentGenerationStepsActions, navigate, options],
    );

    return (
        <div className={cx('page')}>
            <div className="max-constraint center">
                <ContentWithStickySidebar
                    sidebar={<StepSidebar />}
                    offsetTop={80}
                    offsetBottom={100}
                >
                    <div className={cx('brand-header')}>
                        <h1>Location</h1>
                        <div className={cx('brand-search')}>
                            <SearchbarInput
                                isLoading={false}
                                placeholder="Search inside location"
                                value={locationName}
                                onChange={(val: string) => {
                                    setLocationName(val);
                                }}
                                onFocus={() => {}}
                                iconWrapper="icon-wapper"
                                inputWrapper="input-wapper"
                            />
                        </div>
                    </div>

                    <DataLoader isLoading={false} isError={false} error={{}}>
                        <ReactMap
                            handleClick={(country: any) => handleClick(
                                country.properties.name,
                            )}
                            selectedCountry={locationName}
                        />
                    </DataLoader>
                </ContentWithStickySidebar>
            </div>
        </div>
    );
}

export default LocationPage;
